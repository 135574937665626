<template>
	<modal
    :width="570"
    :adaptive="true"
    class="modal-credit-card CEModal modal-scroll-bar"
    name="modalCreditCard"
  >
	  <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <CardForm
      :form-data="formData"
      :randomBackgrounds="false"
      @input-card-number="updateCardNumber"
      @input-card-name="updateCardName"
      @input-card-month="updateCardMonth"
      @input-card-year="updateCardYear"
      @input-card-cvv="updateCardCvv"
      @submit-card = "submitCard"
    />
  </modal>	
</template>

<script>
  import CardForm from '@/components/creditCardForm/CardForm'
  export default {
    name: "ModalCreditCard",
    components: {
      CardForm
    },
    data() {
      return {
        formData: {
          cardName: '',
          cardNumber: '',
          cardMonth: '',
          cardYear: '',
          cardCvv: ''
        },
      }
    },
    
    methods: {
      closeModal() {
        this.$modal.hide("modalCreditCard");
      },
      openModal(cardData={}) {
        this.$modal.show("modalCreditCard");
        if (Object.keys(cardData).length !== 0)
          this.formData = cardData;
      },
      submitCard() {
        this.$emit('submit-card', this.formData);
      },
      updateCardNumber (val) {
      },
      updateCardName (val) {
      },
      updateCardMonth (val) {
      },
      updateCardYear (val) {
      },
      updateCardCvv (val) {
      }
    }
  }
</script>

<style lang="scss">
  .modal-credit-card {
    .vm--modal {
      height: unset !important;
      overflow: unset !important;
      .card-list {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        top: -120px;
        @media screen and (max-width: 480px) {
          position: relative;
          top: 30px;
        }
      }
    }
    .cus-close-modal_btn {
      @media screen and (max-width: 480px) {
        top: 16px;
        img {
          width: 16px;
        }
      }
    }
  }
</style>