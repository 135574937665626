<template>
  <v-card>
    <div class="px-10 font-weight-bold border-bottom-gray d-flex justify-content-between align-items-center flex-wrap">
      <p class="my-5">{{ $t("Orders and Invoices") }}</p>
      <v-tabs
        v-model="tab"
        class="header-tabs"
      >
        <v-tab
          v-for="item in tabTitles"
          :key="item.value"
          class="m-0 font-weight-bold text-none"
          @click="() => handleChangeTab(item.value)"
          small
        >
          {{ item.label }}
        </v-tab>
      </v-tabs>
    </div>
    <v-data-table
      :headers="headers"
      :items="tabData"
      :items-per-page="5"
    >
      <template v-slot:item.amount="{ item }">
        <div class="success--text d-flex" v-if="item.amount >= 0">
          {{ item.amount }}
        </div>
        <div class="error--text d-flex" v-else>
          {{ item.amount }}
        </div>
      </template>
      <template v-slot:item.invoice="{ item }">
        <v-btn
          class="text-none"
          small
        >
          {{ $t("Download") }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  export default {
    name: "InvoiceTab",
    data() {
      return {
        tab: null,
        tabTitles: [
          {value: 2023, label: this.$t("2023")},
          {value: 2022, label: this.$t("2022")},
          {value: 2021, label: this.$t("2021")},
        ],
        headers: [
          {
            text: this.$t("Date"),
            align: 'start',
            sortable: false,
            value: 'date',
          },
          { text: this.$t("Order ID"), value: 'order_id' },
          { text: this.$t("Details"), value: 'details' },
          { text: this.$t("Amount") + " ($) ", value: 'amount' },
          { text: this.$t("Invoice"), value: 'invoice' }
        ],
        tabData: [],
        selectedTab: 2023
      }
    },
    computed: {
      ...mapState({
        invoicesData: (state) => state.end_user.invoicesData,
      }),
    },
    async created() {
      if (this.invoicesData.length === 0) {
        await this.getInvoices();
      }
      this.updateTabData(this.selectedTab);
    },
    methods: {
      ...mapActions('end_user', {
        getInvoices: 'getInvoices'
      }),
      updateTabData(year) {
        let result = [];
        this.invoicesData.forEach(el => {
          if (moment(el.date).format('YYYY') == year) {
            result.push({...el, date: moment(el.date).format('DD/MM/YYYY')})
          }
        });

        this.tabData = result;  
      },
      handleChangeTab(year) {
        this.selectedTab = year;
        this.updateTabData(year);
      }
    }
  }
</script>

<style lang="scss" scoped>
  // .direction-ltr {
  //   direction: ltr !important;
  // }

  // .rtl-type {
  //   .amount {
  //     justify-content: flex-end;
  //   }
  // }
</style>