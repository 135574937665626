<template>
  <div class="mb-20">
    <v-card>
      <div class="px-10 font-weight-bold border-bottom-gray d-flex justify-content-between align-items-center flex-wrap">
        <p class="my-5">{{ $t("Payment Methods") }}</p>
        <v-tabs
          v-model="tab"
          class="header-tabs"
        >
          <v-tab
            v-for="item in tabTitles"
            :key="item"
            class="m-0 font-weight-bold text-none"
            small
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <v-tabs-items class="px-10 py-8 fs14" v-model="tab">
        <v-tab-item>
          <div class="d-flex justify-content-between align-items-end">
            <p class="mb-0 blue-grey--text text--darken-1 font-weight-bold">{{ $t("My Cards") }}</p>
            <v-btn
              color="primary"
              class="text-none"
              small
              @click="openModalAddCreditCard()"
            >
              {{ $t("Add Card") }}
            </v-btn>
          </div>
          <div v-if="creditCardsData.length" class="d-flex justify-content-between flex-wrap mt-5">
            <CCard
              v-for="item in creditCardsData"
              :key="item.id"
              :cardData="item"
              @deleteCard="handleDeleteCCard"
              @editCard="handleEditCCard"
              class="mb-5"
            >
            </CCard>
          </div>
          <div class="mt-5" v-else>
            {{ $t("There is no data") }}
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="d-flex justify-content-between align-items-end">
            <p class="mb-0 blue-grey--text text--darken-1 font-weight-bold">{{ $t("My Bank Accounts") }}</p>
            <v-btn
              color="primary"
              class="text-none"
              small
              @click="openModalBank()"
            >
              {{ $t("Add Bank") }}
            </v-btn>
          </div>
          <div v-if="banksData.length" class="d-flex justify-content-between flex-wrap mt-5">
            <BankCard
              v-for="item in banksData"
              :key="item.id"
              :cardData="item"
              @deleteCard="handleDeleteBank"
              @editCard="handleEditBank"
              class="mb-5"
            />
          </div>
          <div class="mt-5" v-else>
            {{ $t("There is no data") }}
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-10">
      <div class="px-10 py-5 font-weight-bold border-bottom-gray d-flex justify-content-between align-items-center">
        {{ $t("Address Book") }}
        <v-btn
          color="primary"
          class="text-none"
          small
          @click="openModalAddress"
        >
          {{ $t("New Address") }}
        </v-btn>
      </div>
      <div class="px-10 py-8 fs14">
        <div v-if="addressesData.length" class="d-flex justify-content-between flex-wrap mt-5">
          <AddressCard
            v-for="item in addressesData"
            :key="item.id"
            :cardData="item"
            @deleteCard="handleDeleteAddress"
            @editCard="handleEditAddress"
            class="mb-5"
          />
        </div>
        <div class="mt-5" v-else>
          {{ $t("There is no data") }}
        </div>
        <div class="font-weight-bold mt-10">
          <p class="fs16">
            {{ $t("Tax Location") }}
          </p>
          <p class="fs14 blue-grey--text text--darken-1">United States - 10% VAT</p>
        </div>
      </div>
    </v-card>
    <ModalCreditCard ref="modalCreditCardRef" @submit-card="handleSubmitCC"></ModalCreditCard>
    <ModalAddress ref="modalAddressRef" @submit="handleSubmitAddress"></ModalAddress>
    <ModalBank ref="modalBankRef" @submit="handleSubmitBank"></ModalBank>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Swal from 'sweetalert2'
  import ModalCreditCard from "@/components/endUser/account/ModalCreditCard.vue";
  import ModalAddress from "@/components/endUser/account/ModalAddress.vue";
  import ModalBank from "@/components/endUser/account/ModalBank.vue";
  import CCard from "@/components/endUser/account/CCard.vue";
  import AddressCard from "@/components/endUser/account/AddressCard.vue";
  import BankCard from "@/components/endUser/account/BankCard.vue";

  export default {
    name: "BillingTab",
    components: {
      ModalCreditCard,
      ModalAddress,
      ModalBank,
      CCard,
      AddressCard,
      BankCard
    },
    data() {
      return {
        tab: null,
        tabTitles: [
          this.$t("Credit/Debit Card"), 
          // this.$t("PayPal"), 
          this.$t("Bank Account")
        ],
        isAddressEdit: false,
        editAddressId: '',
        isCCEdit: false,
        editCCId: '',
        isBankEdit: false,
        editBankId: ''
      }
    },
    computed: {
      ...mapState({
        creditCardsData: (state) => state.end_user.creditCardsData,
        addressesData: (state) => state.end_user.addressesData,
        banksData: (state) => state.end_user.banksData,
      }),
    },
    async created() {
      if (this.creditCardsData.length === 0) {
        await this.getCreditCards();
      }
      if (this.addressesData.length === 0) {
        await this.getAddresses();
      }
      if (this.banksData.length === 0) {
        await this.getBanks();
      }
    },
    methods: {
      ...mapActions('end_user', {
        createCreditCard: 'createCreditCard',
        getCreditCards: 'getCreditCards',
        updateCreditCard: 'updateCreditCard',
        deleteCreditCard: 'deleteCreditCard',
        createAddress: 'createAddress',
        getAddresses: 'getAddresses',
        updateAddress: 'updateAddress',
        deleteAddress: 'deleteAddress',
        createBank: 'createBank',
        getBanks: 'getBanks',
        updateBank: 'updateBank',
        deleteBank: 'deleteBank',
      }),
      openModalAddCreditCard() {
        this.$refs.modalCreditCardRef.openModal({});
        this.isCCEdit = false;
        
      },
      openModalAddress() {
        this.$refs.modalAddressRef.openModal();
      },
      openModalBank() {
        this.$refs.modalBankRef.openModal();
      },

      // ----------credit card-------------
      async handleSubmitCC(val) {
        const params = {
          cc_number: val.cardNumber,
          cc_holder_name: val.cardName,
          cc_expire_year: val.cardYear,
          cc_expire_month: val.cardMonth,
          cc_cvv: val.cardCvv
        }
        if (this.isCCEdit) {
          await this.updateCreditCard({
            id: this.editCCId,
            params: params
          });
        } else {
          await this.createCreditCard(params);
        }
        this.$refs.modalCreditCardRef.closeModal();
        this.getCreditCards();
      },
      handleDeleteCCard(cardId) {
        Swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("Do you really want to delete this?"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t("Delete"),
          cancelButtonText: this.$t("Cancel"),
          preConfirm: () => {
            this.deleteCreditCard({
              id: cardId
            }).then(res => {
              if (res.message.title == "Success") {
                this.getCreditCards();
              }
            })
          }
        })
      },
      handleEditCCard(data) {
        this.isCCEdit = true;
        this.editCCId = data.id;
        const ccModalData = {
          cardName: data.cc_holder_name,
          cardNumber: "**** **** **** " + data.cc_number,
          cardMonth: data.cc_expire_month,
          cardYear: data.cc_expire_year,
          cardCvv: data.cc_cvv
        }
        this.$refs.modalCreditCardRef.openModal(ccModalData);
      },

      // ----------address-------------
      async handleSubmitAddress(val) {
        val.country = val.country.code;
        if (this.isAddressEdit) {
          await this.updateAddress({
            id: this.editAddressId,
            params: val
          });
        } else {
          await this.createAddress(val);
        }
        this.$refs.modalAddressRef.closeModal();
        await this.getAddresses();
      },
      handleDeleteAddress(cardId) {
        Swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("Do you really want to delete this?"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t("Delete"),
          cancelButtonText: this.$t("Cancel"),
          preConfirm: () => {
            this.deleteAddress({
              id: cardId
            }).then(res => {
              if (res.message.title == "Success") {
                this.getAddresses();
              }
            })
          }
        })
      },
      handleEditAddress(data) {
        this.isAddressEdit = true;
        this.editAddressId = data.id;
        const addressData = {
          name: data.name,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip_code: data.zip_code,
          country: data.country,
          is_billing_address: data.is_billing_address,
        }
        this.$refs.modalAddressRef.openModal(addressData);
      },

      // ----------bank-------------
      async handleSubmitBank(val) {
        if (this.isBankEdit) {
          await this.updateBank({
            id: this.editBankId,
            params: val
          });
        } else {
          await this.createBank(val);
        }
        this.$refs.modalBankRef.closeModal();
        await this.getBanks();
      },
      handleDeleteBank(cardId) {
        Swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("Do you really want to delete this?"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t("Delete"),
          cancelButtonText: this.$t("Cancel"),
          preConfirm: () => {
            this.deleteBank({
              id: cardId
            }).then(res => {
              if (res.message.title == "Success") {
                this.getBanks();
              }
            })
          }
        })
      },
      handleEditBank(data) {
        this.isBankEdit = true;
        this.editBankId = data.id;
        this.$refs.modalBankRef.openModal(data);
      },
    }
  }
</script>

<style lang="scss">
  .payCard_block {
    border: 1px dashed #90A4AE;
    border-radius: 5px;
    width: calc(50% - 20px);
    @media screen and (max-width: 790px) {
      width: 100%;
    }
  }
</style>
