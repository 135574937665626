<template>
  <div class="mb-20">
    <v-card>
      <div class="px-10 py-5 font-weight-bold border-bottom-gray">
        {{ $t("User Details") }}
      </div>
      <div class="px-10 py-8 fs14 border-bottom-gray">
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Profile picture") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <ImgUpload v-model="newUser_data.photo" class="profile-img_upload" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Full Name") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <div class="row">
              <div class="col-sm-6 validation-form-group" :class="{
                'form-group--error': $v.newUser_data.first_name.$error,
              }">
                <input type="text" class="input" :placeholder="$t('First name')" v-model="newUser_data.first_name"
                  @input="$v.newUser_data.first_name.$touch">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.first_name.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
              <div class="col-sm-6 validation-form-group" :class="{
                'form-group--error': $v.newUser_data.last_name.$error,
              }">
                <input type="text" class="input" :placeholder="$t('Last name')" v-model="newUser_data.last_name"
                  @input="$v.newUser_data.last_name.$touch">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.last_name.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Contact Phone") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <VuePhoneNumberInput v-model="newUser_data.phone_number" required :border-radius="0" default-country-code="IL"
              :translations="phoneInputTranslations" @update="onUpdatePhoneNumber" />
            <div class="color-red1 fs12 valide-error-msg" v-if="!isPhoneValid">
              {{ $t('Invalid value') }}
            </div>  
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Email") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <input type="email" class="input" :placeholder="$t('Email')" v-model="newUser_data.email">
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Currency") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <vue-select :options="currencyList" class="input cus-select" v-model="selectedCurrency"></vue-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Country") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <vue-select :options="countryList" class="input cus-select" v-model="selectedCountry"></vue-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Language") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <vue-select :options="languageList" class="input cus-select" v-model="selectedLanguage"></vue-select>
            <span class="fs11">{{ $t("Please select a preferred language for the site and Communication.") }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Display Language") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <vue-select :options="displayLanguageList" class="input cus-select" v-model="selectedDisplayLanguage"></vue-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Time Zone") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <vue-select :options="timezoneList" class="input cus-select" v-model="selectedTimeZone"></vue-select>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Mobile Prepaid") }} <span class="red--text">*</span>
          </div>
          <div class="col-sm-8 col-md-7">
            <div class="d-flex">
              <v-checkbox v-model="newCommunication" :label='$t("Email")' color="primary" value="email" class="mt-0"
                hide-details></v-checkbox>
              <v-checkbox v-model="newCommunication" :label='$t("Phone")' color="primary" value="phone" class="mx-15 mt-0"
                hide-details></v-checkbox>
            </div>
            <div class="mt-5 red--text fs12" v-if="!isValidCommunication">
              {{ $t("At least one must be selected") }}
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-4 col-md-3 fs14 blue-grey--text text--darken-1 font-weight-bold">
            {{ $t("Allow Marketing") }}
          </div>
          <div class="col-sm-8 col-md-7">
            <v-switch v-model="newUser_data.allow_marketing" class="mt-0"></v-switch>
          </div>
        </div>
      </div>
      <div class="row py-5 px-10">
        <div class="col-md-10 d-flex justify-content-end">
          <v-btn class="text-none mx-10">
            {{ $t("Discard") }}
          </v-btn>
          <v-btn color="primary" class="text-none" @click="handleUpdateUser()">
            {{ $t("Save Changes") }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="mt-15">
      <SigninMethodCard :user="user" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { CountriesConst, LanguagesConst, TimezonesConst, CurrenciesConst, DisplayLanguagesConst } from "@/common/constants.js";
import ImgUpload from '@/components/ImgUpload';
import SigninMethodCard from '@/components/endUser/account/SigninMethodCard';

export default {
  name: "SettingsTab",
  mixins: [validationMixin],
  components: {
    ImgUpload,
    SigninMethodCard
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: {}
    },
  },
  validations: {
    newUser_data: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
    },
  },
  created() {
    if (Object.keys(this.user).length !== 0) {
      this.selectedCurrency = this.currencyList.find(el => {
        return el.code == this.user.currency;
      }) || this.currencyList[0];

      this.selectedLanguage = this.languageList.find(el => {
        return el.code == this.user.language;
      }) || this.languageList[0];

      this.selectedTimeZone = this.timezoneList.find(el => {
        return el.code == this.user.time_zone;
      }) || this.timezoneList[0];

      this.selectedCountry = this.countryList.find(el => {
        return el.code == this.user.country;
      }) || this.countryList[0];

      this.selectedDisplayLanguage = this.displayLanguageList.find(el => {
        return el.code == this.user.language_code;
      }) || this.displayLanguageList[0];

      this.newCommunication = this.user.communication.split(",");
    }
  },
  computed: {
    isValidCommunication() {
      if (this.newCommunication.length) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      newUser_data: JSON.parse(JSON.stringify(this.user)),
      countryList: CountriesConst,
      languageList: LanguagesConst,
      timezoneList: TimezonesConst,
      currencyList: CurrenciesConst,
      displayLanguageList: DisplayLanguagesConst,
      isPhoneValid: true,
      newCommunication: [],
      selectedCurrency: {},
      selectedLanguage: {},
      selectedTimeZone: {},
      selectedCountry: {},
      selectedDisplayLanguage: {},
      isAllowMarketing: false,
      image: '',
    }
  },
  methods: {
    ...mapActions('end_user', {
      updateEndUser: 'updateEndUser'
    }),
    onUpdatePhoneNumber(payload) {
      this.isPhoneValid = payload.isValid;
    },
    handleUpdateUser() {
      this.$v.$touch();
      if (this.$v.$anyError || !this.isPhoneValid || !this.isValidCommunication) {
        return;
      }

      const communication_str = this.newCommunication.sort().toString();

      const that = this;
      const id = this.user.id;
      let tempParams = {
        first_name: this.newUser_data.first_name,
        last_name: this.newUser_data.last_name,
        phone_number: this.newUser_data.phone_number,
        // email: this.newUser_data.email,
        currency: this.selectedCurrency.code,
        country: this.selectedCountry.code,
        language: this.selectedLanguage.code,
        time_zone: this.selectedTimeZone.code,
        communication: communication_str,
        allow_marketing: this.newUser_data.allow_marketing,
        photo: this.newUser_data.photo,
        language_code: this.selectedDisplayLanguage.code
      }

      let formData = new FormData();

      for (var key in tempParams) {
        formData.append(key, tempParams[key]);
      }

      this.updateEndUser({
        id: id,
        params: formData
      }).then(() => {
        that.$emit('updateEndUserCallBack');
      });
    },
  }
}
</script>

<style lang="scss">
.profile-img_upload {
  width: 100px;
  height: 100px !important;

  .preview-img {
    object-fit: cover;
  }
}
</style>