<template>
  <modal
    :width="570"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalBank"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			{{ $t("New Bank") }}
		</div>
    <div class="py-10 px-20 fs14">
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.formData.account_name.$error,
      }">
        <label for="account_name">
          {{ $t("Account Name") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="account_name" v-model="formData.account_name">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.account_name.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.formData.bank_name.$error,
      }">
        <label for="bank_name">
          {{ $t("Bank Name") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="bank_name" v-model="formData.bank_name">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.bank_name.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="mb-10">
        <label for="bank_number">
          {{ $t("Bank Number") }}
        </label>
        <input type="text" class="input" id="bank_number" v-model="formData.bank_number">
      </div>
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.formData.branch.$error,
      }">
        <label for="branch">
          {{ $t("Branch") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="branch" v-model="formData.branch">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.branch.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.formData.account_number.$error,
      }">
        <label for="account_number">
          {{ $t("Account Number") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="account_number" v-model="formData.account_number">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.account_number.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <v-btn
          class="text-none mx-10"
          @click="closeModal()"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none"
          @click="submit"
        >
          {{ $t("Save") }}
        </v-btn>
      </div>
    </div>
  </modal>	
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: "ModalBank",
  mixins: [validationMixin],
  data() {
    return {
      formData: {
        account_number: '',
        account_name: '',
        bank_name: '',
        bank_number: '',
        branch: '',
      }
    }
  },
  validations: {
    formData: {
      account_number: {
        required,
      },
      account_name: {
        required,
      },
      bank_name: {
        required,
      },
      branch: {
        required,
      }
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("ModalBank");
    },
    openModal(val={}) {
      this.$modal.show("ModalBank");
      if (Object.keys(val).length !== 0) {
        val.account_number = "****"+ val.account_number;
        this.formData = val;
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (this.formData.account_number.includes("*")) {
        alert(this.$t("Invalid Card Number"));
        return
      }
      this.$emit('submit', this.formData);
    }
  }
}
</script>