<template>
   <div class="p-7 payCard_block">
    <h6 class="font-weight-bold">
      {{ cardData.cc_holder_name }}
    </h6>
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div class="d-flex align-items-center">
        <img src="/assets/img/icons/visa.png" alt="">
        <!-- <img src="/assets/img/icons/mastercard-light-large.png" alt=""> -->
        <div class="blue-grey--text text--darken-1 mx-5">
          <p class="font-weight-bold">
            Visa **** {{ cardData.cc_number }}
          </p>
          <p class="fs11">
            {{ $t("Card expires at") }} {{ cardData.cc_expire_month }}/{{ cardData.cc_expire_year.slice(-2) }}
          </p>
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-btn
          class="text-none mx-5"
          small
          @click="deleteCard"
        >
          {{ $t("Delete") }}
        </v-btn>
        <v-btn
          class="text-none"
          small
          @click="editCard"
        >
          {{ $t("Edit") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCard',
  props: {
    cardData: {
      type: Object,
      required: true,
      default: {}
    },
  },
  methods: {
    deleteCard() {
      this.$emit('deleteCard', this.cardData.id);
    },
    editCard() {
      this.$emit('editCard', this.cardData);
    },
  }
}
</script>
