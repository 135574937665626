var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"CEModal modal-scroll-bar",attrs:{"width":570,"adaptive":true,"name":"ModalBank"}},[_c('button',{staticClass:"button cus-close-modal_btn",on:{"click":function($event){return _vm.closeModal()}}},[_c('img',{attrs:{"src":"/assets/img/close.svg","alt":""}})]),_c('div',{staticClass:"font-weight-bold fs18 grey lighten-3 px-12 py-9"},[_vm._v(" "+_vm._s(_vm.$t("New Bank"))+" ")]),_c('div',{staticClass:"py-10 px-20 fs14"},[_c('div',{staticClass:"mb-10 validation-form-group",class:{
        'form-group--error': _vm.$v.formData.account_name.$error,
      }},[_c('label',{attrs:{"for":"account_name"}},[_vm._v(" "+_vm._s(_vm.$t("Account Name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.account_name),expression:"formData.account_name"}],staticClass:"input",attrs:{"type":"text","id":"account_name"},domProps:{"value":(_vm.formData.account_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "account_name", $event.target.value)}}}),(_vm.$v.formData.account_name.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-10 validation-form-group",class:{
        'form-group--error': _vm.$v.formData.bank_name.$error,
      }},[_c('label',{attrs:{"for":"bank_name"}},[_vm._v(" "+_vm._s(_vm.$t("Bank Name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.bank_name),expression:"formData.bank_name"}],staticClass:"input",attrs:{"type":"text","id":"bank_name"},domProps:{"value":(_vm.formData.bank_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "bank_name", $event.target.value)}}}),(_vm.$v.formData.bank_name.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-10"},[_c('label',{attrs:{"for":"bank_number"}},[_vm._v(" "+_vm._s(_vm.$t("Bank Number"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.bank_number),expression:"formData.bank_number"}],staticClass:"input",attrs:{"type":"text","id":"bank_number"},domProps:{"value":(_vm.formData.bank_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "bank_number", $event.target.value)}}})]),_c('div',{staticClass:"mb-10 validation-form-group",class:{
        'form-group--error': _vm.$v.formData.branch.$error,
      }},[_c('label',{attrs:{"for":"branch"}},[_vm._v(" "+_vm._s(_vm.$t("Branch"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.branch),expression:"formData.branch"}],staticClass:"input",attrs:{"type":"text","id":"branch"},domProps:{"value":(_vm.formData.branch)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "branch", $event.target.value)}}}),(_vm.$v.formData.branch.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-10 validation-form-group",class:{
        'form-group--error': _vm.$v.formData.account_number.$error,
      }},[_c('label',{attrs:{"for":"account_number"}},[_vm._v(" "+_vm._s(_vm.$t("Account Number"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.account_number),expression:"formData.account_number"}],staticClass:"input",attrs:{"type":"text","id":"account_number"},domProps:{"value":(_vm.formData.account_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "account_number", $event.target.value)}}}),(_vm.$v.formData.account_number.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{staticClass:"text-none mx-10",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }