<template>
  <modal
    :width="570"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalAddress"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			{{ $t("New Address") }}
		</div>
    <div class="py-10 px-20 fs14">
      <div 
        class="mb-10 validation-form-group"
        :class="{
          'form-group--error': $v.formData.name.$error,
        }"
      >
        <label for="name">
          {{ $t("Address Name") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="name" v-model="formData.name">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.name.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.formData.address1.$error,
      }">
        <label for="address1">
          {{ $t("Address Line 1") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="address1" v-model="formData.address1">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.address1.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="mb-10">
        <label for="name">
          {{ $t("Address Line 2") }}
        </label>
        <input type="text" class="input" id="name" v-model="formData.address2">
      </div>
      <div class="mb-10 validation-form-group" :class="{
        'form-group--error': $v.formData.city.$error,
      }">
        <label for="city">
          {{ $t("City / Town") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="city" v-model="formData.city">
        <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.city.$error">
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div class="row mb-10">
        <div class="col-sm-6 validation-form-group" :class="{
          'form-group--error': $v.formData.state.$error,
        }">
          <label for="state">
            {{ $t("State / Province") }} <span class="red--text">*</span>
          </label>
          <input type="text" class="input" id="state" v-model="formData.state">
          <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.state.$error">
            {{ $t('Invalid value') }}
          </div>
        </div>
        <div class="col-sm-6 validation-form-group" :class="{
          'form-group--error': $v.formData.zip_code.$error,
        }">
          <label for="zip_code">
            {{ $t("Post Code") }} <span class="red--text">*</span>
          </label>
          <input type="text" class="input" id="zip_code" v-model="formData.zip_code">
          <div class="color-red1 fs12 valide-error-msg" v-if="$v.formData.zip_code.$error">
            {{ $t('Invalid value') }}
          </div>
        </div>
      </div>
      <div class="mb-10">
        <label for="name">
          {{ $t("Country") }} <span class="red--text">*</span>
        </label>
        <vue-select
          :options="countryList"
          class="input cus-select"
          v-model="formData.country"
        ></vue-select>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-15">
        <p>{{ $t("Use as a billing address?") }}</p>
        <v-switch
          v-model="formData.is_billing_address"
          color="primary"
          hide-details
        ></v-switch>
      </div>
      <div class="d-flex justify-content-end">
        <v-btn
          class="text-none mx-10"
          @click="closeModal()"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none"
          @click="submit"
        >
          {{ $t("Save") }}
        </v-btn>
      </div>
    </div>
  </modal>	
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { CountriesConst } from "@/common/constants.js";

export default {
  name: "ModalAddress",
  mixins: [validationMixin],
  data() {
    return {
      countryList: CountriesConst,
      formData: {
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        is_billing_address: false,
      }
    }
  },
  validations: {
    formData: {
      name: {
        required,
      },
      address1: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zip_code: {
        required,
      }
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("ModalAddress");
    },
    openModal(val={}) {
      this.$modal.show("ModalAddress");
      if (Object.keys(val).length !== 0) {
        val.country = this.countryList.find(el => {
          return el.code == val.country;
        });
        this.formData = val;
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('submit', this.formData);
    }
  }
}
</script>