<template>
  <modal
    :width="570"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalTraveler"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			{{ $t("New Address") }}
		</div>
    <div class="py-10 px-20 fs14">
      <div class="mb-10">
        <label for="name">
          {{ $t("Address Name") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="name">
      </div>
      <div class="mb-10">
        <label for="name">
          {{ $t("Address Line 1") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="name">
      </div>
      <div class="mb-10">
        <label for="name">
          {{ $t("Address Line 2") }}
        </label>
        <input type="text" class="input" id="name">
      </div>
      <div class="mb-10">
        <label for="name">
          {{ $t("City / Town") }} <span class="red--text">*</span>
        </label>
        <input type="text" class="input" id="name">
      </div>
      <div class="row mb-10">
        <div class="col-sm-6">
          <label for="name">
            {{ $t("State / Province") }} <span class="red--text">*</span>
          </label>
          <input type="text" class="input" id="name">
        </div>
        <div class="col-sm-6">
          <label for="name">
            {{ $t("Post Code") }} <span class="red--text">*</span>
          </label>
          <input type="text" class="input" id="name">
        </div>
      </div>
      <div class="mb-10">
        <label for="name">
          {{ $t("Country") }} <span class="red--text">*</span>
        </label>
        <vue-select
          :options="countryList"
          class="input cus-select"
          v-model="newCountry"
        ></vue-select>
      </div>

      <div>
        <div class="tour-passport__photo">
          <svg
            class="tour-passport__svg"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
          >
            <path
              d="M11.429,48.786a5.357,5.357,0,1,1,5.357-5.357A5.361,5.361,0,0,1,11.429,48.786Zm0-9.286a3.929,3.929,0,1,0,3.929,3.929A3.934,3.934,0,0,0,11.429,39.5ZM10,43.429A1.433,1.433,0,0,1,11.429,42a.714.714,0,0,0,0-1.429,2.86,2.86,0,0,0-2.857,2.857.714.714,0,1,0,1.429,0Zm4.478-10a.362.362,0,0,1,.335.232l.987,2.625h4.915a.716.716,0,0,1,.714.714V49.857a.716.716,0,0,1-.714.714H2.143a.716.716,0,0,1-.714-.714V37a.716.716,0,0,1,.714-.714h4.92l.9-2.393a.716.716,0,0,1,.67-.464h5.848m0-1.429H8.629a2.14,2.14,0,0,0-2,1.388l-.554,1.469H2.143A2.143,2.143,0,0,0,0,37V49.857A2.143,2.143,0,0,0,2.143,52H20.714a2.143,2.143,0,0,0,2.143-2.143V37a2.143,2.143,0,0,0-2.143-2.143H16.786l-.638-1.7A1.775,1.775,0,0,0,14.478,32Z"
              transform="translate(0 -32)"
            />
          </svg>
          <!-- <input
            class="tour-passport__file"
            type="file"
            @change="handleImage(roomPassport.id)"
            accept="image/*"
            :ref="'file' + roomPassport.id"
            multiple="multiple"
          /> -->
        </div>
        <p class="text tour-passport__text-passport">
          {{ $t('Passport photo') }}
        </p>

        <p class="text tour-passport__text-cant">
          {{ $t("Can't take photos?") }}
        </p>

        <button
          class="link tour-passport__link"
          @click="goManualToCreate(roomPassport.id)"
        >
          {{ $t('Enter details manually') }}
        </button>
      </div>


      <div class="d-flex justify-content-end">
        <v-btn
          class="text-none mx-10"
          @click="closeModal()"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none"
        >
          {{ $t("Save") }}
        </v-btn>
      </div>
    </div>
  </modal>	
</template>

<script>
import { CountriesConst } from "@/common/constants.js";

export default {
  name: "ModalTraveler",
  data() {
    return {
      countryList: CountriesConst,
      newCountry: {code: 'IL', label: 'Israel'},
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide("ModalTraveler");
    },
    openModal() {
      this.$modal.show("ModalTraveler");
    },
    async handleImage(id) {
    //   let formData = new FormData();
    //   for (var i = 0; i < this.$refs['file' + id][0].files.length; i++) {
    //     let file = this.$refs['file' + id][0].files[i];
    //     formData.append('scan_images[' + i + ']', file);
    //   }

    //   localStorage.setItem('roomId', id);
    //   await this.uploadPassImage({ scan_images: formData });

    //   if (!this.passportImageTempData) {
    //     this.$modal.show('errorModal');
    //   }
    },
  }
}
</script>