<template>
  <div>
    <v-card>
      <div class="px-10 py-5 font-weight-bold border-bottom-gray">
        {{ $t("User Details") }}
      </div>
      <div class="px-10 py-8 fs14">
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Full Name") }}</label>
          <span>{{ fullName }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Contact Phone") }}</label>
          <span>{{ phone }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Email") }}</label>
          <span>{{ email }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Currency") }}</label>
          <span>{{ currency }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Country") }}</label>
          <span>{{ country }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Main Address") }}</label>
          <span>{{ address }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Mobile Prepaid") }}</label>
          <span>{{ communication.replaceAll(',', ', ') }}</span>
        </div>
        <div class="d-flex detail-item mb-5">
          <label class="fs14 blue-grey--text text--darken-1 overview_label font-weight-bold">{{ $t("Allow Marketing") }}</label>
          <span class="text-capitalize">{{ allow_marketing }}</span>
        </div>
      </div>
    </v-card>
    <!-- <div class="border-bottom-gray d-flex justify-content-between mt-10 px-10 py-5">
      <span class="font-weight-bold">{{ $t("Saved travelers") }}</span>
      <v-btn
        color="primary"
        class="text-none"
        small
        @click="openModalTraveler"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        {{ $t("Add a traveler") }}
      </v-btn>
    </div>
    <div class="travelers d-flex justify-content-between flex-wrap">
      <v-card 
        class="d-flex align-items-center justify-content-between p-5 mt-5 traveler-card"
        v-for="(card, i) in TavelerCardsData" 
        :key="i"
      >
        <div>
          <p class="font-weight-bold blue-grey--text text--darken-1 mb-0">
            {{ card.name }}
          </p>
          <span class="blue-grey--text text--darken-1 fs12">
            {{ card.birthday }}
          </span>
        </div>
        <v-btn
          color="primary"
          class="text-none"
          small
        >
          <v-icon left>
            mdi-pencil-outline
          </v-icon>
          {{ $t("Edit") }}
        </v-btn>
      </v-card>
    </div> -->
    <ModalTraveler ref="modalTravelerRef"></ModalTraveler>
  </div>
</template>

<script>
import ModalTraveler from "@/components/endUser/account/ModalTraveler.vue";

export default {
  name: "OverviewTab",
  components: {
    ModalTraveler
  },  
  props: {
    fullName: String,
    phone: String,
    email: String,
    currency: String,
    country: String,
    address: String,
    communication: String,
    allow_marketing: Boolean
  },
  data() {
    return {
      TavelerCardsData: [
        {  
          name: 'Jon Dou', 
          birthday: '23 June 1980',
        },
        {  
          name: 'Jane Dou', 
          birthday: '10 August 1984',
        },
        {  
          name: 'Boy Dou', 
          birthday: '23 June 1999',
        },
        {  
          name: 'Girl Dou', 
          birthday: '10 August 2002',
        },
      ],
    }
  },
  methods: {
    openModalTraveler() {
      this.$refs.modalTravelerRef.openModal();
    },
  }
}
</script>

<style lang="scss" scoped>
  .detail-item {
    @media screen and (max-width: 520px) {
      flex-direction: column;
    }
    .overview_label {
      width: 200px;
    } 
  }
  .travelers {
    .traveler-card {
      width: calc(25% - 30px);
      @media screen and (max-width: 900px) {
        width: calc(25% - 10px);
      }
      @media screen and (max-width: 810px) {
        width: calc(50% - 10px);
      }
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }
  }

</style>