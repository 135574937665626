var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"CEModal modal-scroll-bar",attrs:{"width":570,"adaptive":true,"name":"ModalAddress"}},[_c('button',{staticClass:"button cus-close-modal_btn",on:{"click":function($event){return _vm.closeModal()}}},[_c('img',{attrs:{"src":"/assets/img/close.svg","alt":""}})]),_c('div',{staticClass:"font-weight-bold fs18 grey lighten-3 px-12 py-9"},[_vm._v(" "+_vm._s(_vm.$t("New Address"))+" ")]),_c('div',{staticClass:"py-10 px-20 fs14"},[_c('div',{staticClass:"mb-10 validation-form-group",class:{
          'form-group--error': _vm.$v.formData.name.$error,
        }},[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t("Address Name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"input",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value)}}}),(_vm.$v.formData.name.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-10 validation-form-group",class:{
        'form-group--error': _vm.$v.formData.address1.$error,
      }},[_c('label',{attrs:{"for":"address1"}},[_vm._v(" "+_vm._s(_vm.$t("Address Line 1"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.address1),expression:"formData.address1"}],staticClass:"input",attrs:{"type":"text","id":"address1"},domProps:{"value":(_vm.formData.address1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "address1", $event.target.value)}}}),(_vm.$v.formData.address1.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-10"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t("Address Line 2"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.address2),expression:"formData.address2"}],staticClass:"input",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.formData.address2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "address2", $event.target.value)}}})]),_c('div',{staticClass:"mb-10 validation-form-group",class:{
        'form-group--error': _vm.$v.formData.city.$error,
      }},[_c('label',{attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.$t("City / Town"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.city),expression:"formData.city"}],staticClass:"input",attrs:{"type":"text","id":"city"},domProps:{"value":(_vm.formData.city)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "city", $event.target.value)}}}),(_vm.$v.formData.city.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"row mb-10"},[_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.formData.state.$error,
        }},[_c('label',{attrs:{"for":"state"}},[_vm._v(" "+_vm._s(_vm.$t("State / Province"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.state),expression:"formData.state"}],staticClass:"input",attrs:{"type":"text","id":"state"},domProps:{"value":(_vm.formData.state)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "state", $event.target.value)}}}),(_vm.$v.formData.state.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.formData.zip_code.$error,
        }},[_c('label',{attrs:{"for":"zip_code"}},[_vm._v(" "+_vm._s(_vm.$t("Post Code"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.zip_code),expression:"formData.zip_code"}],staticClass:"input",attrs:{"type":"text","id":"zip_code"},domProps:{"value":(_vm.formData.zip_code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "zip_code", $event.target.value)}}}),(_vm.$v.formData.zip_code.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()])]),_c('div',{staticClass:"mb-10"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t("Country"))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('vue-select',{staticClass:"input cus-select",attrs:{"options":_vm.countryList},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-15"},[_c('p',[_vm._v(_vm._s(_vm.$t("Use as a billing address?")))]),_c('v-switch',{attrs:{"color":"primary","hide-details":""},model:{value:(_vm.formData.is_billing_address),callback:function ($$v) {_vm.$set(_vm.formData, "is_billing_address", $$v)},expression:"formData.is_billing_address"}})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{staticClass:"text-none mx-10",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }