<template>
  <div>
    <v-card>
      <div class="px-10 py-5 font-weight-bold border-bottom-gray d-flex justify-content-between align-items-center">
        {{ $t("Upcoming trips") }}
        <router-link 
          to="/enduser/tourism">
          <v-btn
            color="primary"
            class="text-none"
            small
          >
            <v-icon left color="white">
              mdi-earth
            </v-icon>
            {{ $t("Search trips") }}
          </v-btn>
        </router-link>
      </div>
      <div class="px-10 py-5 d-flex flex-wrap upcoming-trips">
        <span class="grey--text">{{ $t("There is no data") }}</span> 
        <!-- <TripsCard
          v-for="(card, i) in TripsCardsData" 
          :key="i" 
          :start="card.start"
          :end="card.end"
          :date="card.date"
          :passenger="card.passenger"
          :isShowImage="card.isShowImage"
          :image="card.image"
          class="mb-10 mx-2"
        >
        </TripsCard> -->
      </div>
    </v-card>
    <v-card class="mt-15">
      <div class="px-10 font-weight-bold border-bottom-gray d-flex justify-content-between align-items-center flex-wrap">
        <p class="my-5">{{ $t("Past trips") }}</p>
        <v-tabs
          v-model="tab"
          class="header-tabs"
        >
          <v-tab
            v-for="item in tabTitles"
            :key="item"
            class="m-0 font-weight-bold text-none"
            small
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="px-10 py-5 d-flex flex-wrap upcoming-trips">
        <span class="grey--text">{{ $t("There is no data") }}</span> 
        <!-- <TripsCard
          v-for="(card, i) in TripsCardsData" 
          :key="i" 
          :start="card.start"
          :end="card.end"
          :date="card.date"
          :passenger="card.passenger"
          :isShowImage="card.isShowImage"
          :image="card.image"
          :isBwImage="true"
          class="mb-10 mx-2"
        >
        </TripsCard> -->
      </div>
    </v-card>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import TripsCard from '@/components/endUser/account/TripsCard';

  export default {
    name: "MyTripsTab",
    components: {
      TripsCard
    },
    data() {
      return {
        tab: null,
        tabTitles: [
          this.$t("2023"), 
          this.$t("2022"), 
          this.$t("2021")
        ],
        TripsCardsData: [
          {  
            start: 'Tel Aviv', 
            end: 'Porto',
            date: '25/02/2023 - 02/03/2023',
            passenger: 2,
            isShowImage: true,
            image: "/assets/img/terrace.png"
          },
          {  
            start: 'Tel Aviv', 
            end: 'Porto',
            date: '25/02/2023 - 02/03/2023',
            passenger: 2,
            isShowImage: true,
            image: "/assets/img/terrace.png"
          },
          {  
            start: 'Tel Aviv', 
            end: 'Porto',
            date: '25/02/2023 - 02/03/2023',
            passenger: 2,
            isShowImage: true,
            image: "/assets/img/terrace.png"
          },
          {  
            start: 'Tel Aviv', 
            end: 'Berlin',
            date: '25/03/2023 - 02/04/2023',
            passenger: 4,
            isShowImage: true,
            image: "/assets/img/terrace.png"
          }
        ],
      }
    },
    computed: {
      ...mapState({
        tripsData: (state) => state.end_user.tripsData,
      }),
    },
    async created() {
      // if (this.tripsData.length === 0) {
      //   const params = {
      //     date:'2023-05-17',
      //     returnDate: '2023-05-20', 
      //     dealType: 'NOFSHON',
      //     destinationList: ['HER'],
      //     departure: 'TLV',
      //     numberOfAdults: 2,
      //     numberOfChildren: 0
      //   }
      //   await this.getTrips(params);

      // }
      // this.updateTabData(this.selectedTab);
    },
    methods: {
      ...mapActions('end_user', {
        getTrips: 'getTrips'
      }),
      // updateTabData(year) {
      //   let result = [];
      //   this.invoicesData.forEach(el => {
      //     if (moment(el.date).format('YYYY') == year) {
      //       result.push({...el, date: moment(el.date).format('DD/MM/YYYY')})
      //     }
      //   });

      //   this.tabData = result;  
      // },
      handleChangeTab(year) {
        this.selectedTab = year;
        this.updateTabData(year);
      }
    }
  }
</script>

<style lang="scss" scoped>
.upcoming-trips {
  justify-content: space-between;
  @media screen and (max-width: 950px) {
    justify-content: center;
  }
}
</style>