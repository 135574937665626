<template>
  <v-card 
    outlined 
    elevation="1" 
    width="270"
  >
    <v-img
      v-show="isShowImage"
      height="152"
      :src="image"
      :class="{'bw-img': isBwImage}"
    ></v-img>
    <div class="p-2">
      <p class="mb-0 font-weight-bold blue-grey--text text--darken-1 fs14">
        {{ start }} <v-icon class="mx-3" small>mdi-swap-horizontal</v-icon> {{ end }}
      </p>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <v-icon small>mdi-calendar-month</v-icon>
          <p class="mx-3 mb-0 fs12">{{ date }}</p> 
        </div>
        <v-chip
          class="mx-3"
          color="blue-grey darken-1"
          label
          text-color="white"
          small
        >
          <v-icon left>
            mdi-account-multiple
          </v-icon>
          {{ passenger }}
        </v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "TripsCard",
  props: {
    start: String,
    end: String,
    date: String,
    passenger: Number,
    isShowImage: {
      type: Boolean,
      default: false
    },
    isBwImage: {
      type: Boolean,
      default: false
    },
    image: String
  }
}
</script>
