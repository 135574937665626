export const CountriesConst = [
  {label: "Afghanistan",code: "AF"},
  {label: "Åland Islands",code: "AX"},
  {label: "Albania",code: "AL"},
  {label: "Algeria",code: "DZ"},
  {label: "American Samoa",code: "AS"},
  {label: "Andorra",code: "AD"},
  {label: "Angola",code: "AO"},
  {label: "Anguilla",code: "AI"},
  {label: "Antarctica",code: "AQ"},
  {label: "Antigua & Barbuda",code: "AG"},
  {label: "Argentina",code: "AR"},
  {label: "Armenia",code: "AM"},
  {label: "Aruba",code: "AW"},
  {label: "Australia",code: "AU"},
  {label: "Austria",code: "AT"},
  {label: "Azerbaijan",code: "AZ"},
  {label: "Bahamas",code: "BS"},
  {label: "Bahrain",code: "BH"},
  {label: "Bangladesh",code: "BD"},
  {label: "Barbados",code: "BB"},
  {label: "Belarus",code: "BY"},
  {label: "Belgium",code: "BE"},
  {label: "Belize",code: "BZ"},
  {label: "Benin",code: "BJ"},
  {label: "Bermuda",code: "BM"},
  {label: "Bhutan",code: "BT"},
  {label: "Bolivia",code: "BO"},
  {label: "Caribbean Netherlands",code: "BQ"},
  {label: "Bosnia & Herzegovina",code: "BA"},
  {label: "Botswana",code: "BW"},
  {label: "Bouvet Island",code: "BV"},
  {label: "Brazil",code: "BR"},
  {label: "British Indian Ocean Territory",code: "IO"},
  {label: "Brunei",code: "BN"},
  {label: "Bulgaria",code: "BG"},
  {label: "Burkina Faso",code: "BF"},
  {label: "Burundi",code: "BI"},
  {label: "Cambodia",code: "KH"},
  {label: "Cameroon",code: "CM"},
  {label: "Canada",code: "CA"},
  {label: "Cape Verde",code: "CV"},
  {label: "Cayman Islands",code: "KY"},
  {label: "Central African Republic",code: "CF"},
  {label: "Chad",code: "TD"},
  {label: "Chile",code: "CL"},
  {label: "China",code: "CN"},
  {label: "Christmas Island",code: "CX"},
  {label: "Cocos (Keeling) Islands",code: "CC"},
  {label: "Colombia",code: "CO"},
  {label: "Comoros",code: "KM"},
  {label: "Congo - Brazzaville",code: "CG"},
  {label: "Congo - Kinshasa",code: "CD"},
  {label: "Cook Islands",code: "CK"},
  {label: "Costa Rica",code: "CR"},
  {label: "Côte d’Ivoire",code: "CI"},
  {label: "Croatia",code: "HR"},
  {label: "Cuba",code: "CU"},
  {label: "Curaçao",code: "CW"},
  {label: "Cyprus",code: "CY"},
  {label: "Czechia",code: "CZ"},
  {label: "Denmark",code: "DK"},
  {label: "Djibouti",code: "DJ"},
  {label: "Dominica",code: "DM"},
  {label: "Dominican Republic",code: "DO"},
  {label: "Ecuador",code: "EC"},
  {label: "Egypt",code: "EG"},
  {label: "El Salvador",code: "SV"},
  {label: "Equatorial Guinea",code: "GQ"},
  {label: "Eritrea",code: "ER"},
  {label: "Estonia",code: "EE"},
  {label: "Ethiopia",code: "ET"},
  {label: "Falkland Islands (Islas Malvinas)",code: "FK"},
  {label: "Faroe Islands",code: "FO"},
  {label: "Fiji",code: "FJ"},
  {label: "Finland",code: "FI"},
  {label: "France",code: "FR"},
  {label: "French Guiana",code: "GF"},
  {label: "French Polynesia",code: "PF"},
  {label: "French Southern Territories",code: "TF"},
  {label: "Gabon",code: "GA"},
  {label: "Gambia",code: "GM"},
  {label: "Georgia",code: "GE"},
  {label: "Germany",code: "DE"},
  {label: "Ghana",code: "GH"},
  {label: "Gibraltar",code: "GI"},
  {label: "Greece",code: "GR"},
  {label: "Greenland",code: "GL"},
  {label: "Grenada",code: "GD"},
  {label: "Guadeloupe",code: "GP"},
  {label: "Guam",code: "GU"},
  {label: "Guatemala",code: "GT"},
  {label: "Guernsey",code: "GG"},
  {label: "Guinea",code: "GN"},
  {label: "Guinea-Bissau",code: "GW"},
  {label: "Guyana",code: "GY"},
  {label: "Haiti",code: "HT"},
  {label: "Heard & McDonald Islands",code: "HM"},
  {label: "Vatican City",code: "VA"},
  {label: "Honduras",code: "HN"},
  {label: "Hong Kong",code: "HK"},
  {label: "Hungary",code: "HU"},
  {label: "Iceland",code: "IS"},
  {label: "India",code: "IN"},
  {label: "Indonesia",code: "ID"},
  {label: "Iran",code: "IR"},
  {label: "Iraq",code: "IQ"},
  {label: "Ireland",code: "IE"},
  {label: "Isle of Man",code: "IM"},
  {label: "Israel",code: "IL"},
  {label: "Italy",code: "IT"},
  {label: "Jamaica",code: "JM"},
  {label: "Japan",code: "JP"},
  {label: "Jersey",code: "JE"},
  {label: "Jordan",code: "JO"},
  {label: "Kazakhstan",code: "KZ"},
  {label: "Kenya",code: "KE"},
  {label: "Kiribati",code: "KI"},
  {label: "North Korea",code: "KP"},
  {label: "South Korea",code: "KR"},
  {label: "Kosovo",code: "XK"},
  {label: "Kuwait",code: "KW"},
  {label: "Kyrgyzstan",code: "KG"},
  {label: "Laos",code: "LA"},
  {label: "Latvia",code: "LV"},
  {label: "Lebanon",code: "LB"},
  {label: "Lesotho",code: "LS"},
  {label: "Liberia",code: "LR"},
  {label: "Libya",code: "LY"},
  {label: "Liechtenstein",code: "LI"},
  {label: "Lithuania",code: "LT"},
  {label: "Luxembourg",code: "LU"},
  {label: "Macao",code: "MO"},
  {label: "North Macedonia",code: "MK"},
  {label: "Madagascar",code: "MG"},
  {label: "Malawi",code: "MW"},
  {label: "Malaysia",code: "MY"},
  {label: "Maldives",code: "MV"},
  {label: "Mali",code: "ML"},
  {label: "Malta",code: "MT"},
  {label: "Marshall Islands",code: "MH"},
  {label: "Martinique",code: "MQ"},
  {label: "Mauritania",code: "MR"},
  {label: "Mauritius",code: "MU"},
  {label: "Mayotte",code: "YT"},
  {label: "Mexico",code: "MX"},
  {label: "Micronesia",code: "FM"},
  {label: "Moldova",code: "MD"},
  {label: "Monaco",code: "MC"},
  {label: "Mongolia",code: "MN"},
  {label: "Montenegro",code: "ME"},
  {label: "Montserrat",code: "MS"},
  {label: "Morocco",code: "MA"},
  {label: "Mozambique",code: "MZ"},
  {label: "Myanmar (Burma)",code: "MM"},
  {label: "Namibia",code: "NA"},
  {label: "Nauru",code: "NR"},
  {label: "Nepal",code: "NP"},
  {label: "Netherlands",code: "NL"},
  {label: "Curaçao",code: "AN"},
  {label: "New Caledonia",code: "NC"},
  {label: "New Zealand",code: "NZ"},
  {label: "Nicaragua",code: "NI"},
  {label: "Niger",code: "NE"},
  {label: "Nigeria",code: "NG"},
  {label: "Niue",code: "NU"},
  {label: "Norfolk Island",code: "NF"},
  {label: "Northern Mariana Islands",code: "MP"},
  {label: "Norway",code: "NO"},
  {label: "Oman",code: "OM"},
  {label: "Pakistan",code: "PK"},
  {label: "Palau",code: "PW"},
  {label: "Palestine",code: "PS"},
  {label: "Panama",code: "PA"},
  {label: "Papua New Guinea",code: "PG"},
  {label: "Paraguay",code: "PY"},
  {label: "Peru",code: "PE"},
  {label: "Philippines",code: "PH"},
  {label: "Pitcairn Islands",code: "PN"},
  {label: "Poland",code: "PL"},
  {label: "Portugal",code: "PT"},
  {label: "Puerto Rico",code: "PR"},
  {label: "Qatar",code: "QA"},
  {label: "Réunion",code: "RE"},
  {label: "Romania",code: "RO"},
  {label: "Russia",code: "RU"},
  {label: "Rwanda",code: "RW"},
  {label: "St. Barthélemy",code: "BL"},
  {label: "St. Helena",code: "SH"},
  {label: "St. Kitts & Nevis",code: "KN"},
  {label: "St. Lucia",code: "LC"},
  {label: "St. Martin",code: "MF"},
  {label: "St. Pierre & Miquelon",code: "PM"},
  {label: "St. Vincent & Grenadines",code: "VC"},
  {label: "Samoa",code: "WS"},
  {label: "San Marino",code: "SM"},
  {label: "São Tomé & Príncipe",code: "ST"},
  {label: "Saudi Arabia",code: "SA"},
  {label: "Senegal",code: "SN"},
  {label: "Serbia",code: "RS"},
  {label: "Serbia",code: "CS"},
  {label: "Seychelles",code: "SC"},
  {label: "Sierra Leone",code: "SL"},
  {label: "Singapore",code: "SG"},
  {label: "Sint Maarten",code: "SX"},
  {label: "Slovakia",code: "SK"},
  {label: "Slovenia",code: "SI"},
  {label: "Solomon Islands",code: "SB"},
  {label: "Somalia",code: "SO"},
  {label: "South Africa",code: "ZA"},
  {label: "South Georgia & South Sandwich Islands",code: "GS"},
  {label: "South Sudan",code: "SS"},
  {label: "Spain",code: "ES"},
  {label: "Sri Lanka",code: "LK"},
  {label: "Sudan",code: "SD"},
  {label: "Suriname",code: "SR"},
  {label: "Svalbard & Jan Mayen",code: "SJ"},
  {label: "Eswatini",code: "SZ"},
  {label: "Sweden",code: "SE"},
  {label: "Switzerland",code: "CH"},
  {label: "Syria",code: "SY"},
  {label: "Taiwan",code: "TW"},
  {label: "Tajikistan",code: "TJ"},
  {label: "Tanzania",code: "TZ"},
  {label: "Thailand",code: "TH"},
  {label: "Timor-Leste",code: "TL"},
  {label: "Togo",code: "TG"},
  {label: "Tokelau",code: "TK"},
  {label: "Tonga",code: "TO"},
  {label: "Trinidad & Tobago",code: "TT"},
  {label: "Tunisia",code: "TN"},
  {label: "Turkey",code: "TR"},
  {label: "Turkmenistan",code: "TM"},
  {label: "Turks & Caicos Islands",code: "TC"},
  {label: "Tuvalu",code: "TV"},
  {label: "Uganda",code: "UG"},
  {label: "Ukraine",code: "UA"},
  {label: "United Arab Emirates",code: "AE"},
  {label: "United Kingdom",code: "GB"},
  {label: "United States",code: "US"},
  {label: "U.S. Outlying Islands",code: "UM"},
  {label: "Uruguay",code: "UY"},
  {label: "Uzbekistan",code: "UZ"},
  {label: "Vanuatu",code: "VU"},
  {label: "Venezuela",code: "VE"},
  {label: "Vietnam",code: "VN"},
  {label: "British Virgin Islands",code: "VG"},
  {label: "U.S. Virgin Islands",code: "VI"},
  {label: "Wallis & Futuna",code: "WF"},
  {label: "Western Sahara",code: "EH"},
  {label: "Yemen",code: "YE"},
  {label: "Zambia",code: "ZM"},
  {label: "Zimbabwe",code: "ZW"}
];

export const LanguagesConst = [
  { "code": "aa", "label": "Afar" },
  { "code": "ab", "label": "Abkhazian" },
  { "code": "ae", "label": "Avestan" },
  { "code": "af", "label": "Afrikaans" },
  { "code": "ak", "label": "Akan" },
  { "code": "am", "label": "Amharic" },
  { "code": "an", "label": "Aragonese" },
  { "code": "ar", "label": "Arabic" },
  { "code": "as", "label": "Assamese" },
  { "code": "av", "label": "Avaric" },
  { "code": "ay", "label": "Aymara" },
  { "code": "az", "label": "Azerbaijani" },
  { "code": "ba", "label": "Bashkir" },
  { "code": "be", "label": "Belarusian" },
  { "code": "bg", "label": "Bulgarian" },
  { "code": "bh", "label": "Bihari languages" },
  { "code": "bi", "label": "Bislama" },
  { "code": "bm", "label": "Bambara" },
  { "code": "bn", "label": "Bengali" },
  { "code": "bo", "label": "Tibetan" },
  { "code": "br", "label": "Breton" },
  { "code": "bs", "label": "Bosnian" },
  { "code": "ca", "label": "Catalan; Valencian" },
  { "code": "ce", "label": "Chechen" },
  { "code": "ch", "label": "Chamorro" },
  { "code": "co", "label": "Corsican" },
  { "code": "cr", "label": "Cree" },
  { "code": "cs", "label": "Czech" },
  {
    "code": "cu",
    "label": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
  },
  { "code": "cv", "label": "Chuvash" },
  { "code": "cy", "label": "Welsh" },
  { "code": "da", "label": "Danish" },
  { "code": "de", "label": "German" },
  { "code": "dv", "label": "Divehi; Dhivehi; Maldivian" },
  { "code": "dz", "label": "Dzongkha" },
  { "code": "ee", "label": "Ewe" },
  { "code": "el", "label": "Greek, Modern (1453-)" },
  { "code": "en", "label": "English" },
  { "code": "eo", "label": "Esperanto" },
  { "code": "es", "label": "Spanish; Castilian" },
  { "code": "et", "label": "Estonian" },
  { "code": "eu", "label": "Basque" },
  { "code": "fa", "label": "Persian" },
  { "code": "ff", "label": "Fulah" },
  { "code": "fi", "label": "Finnish" },
  { "code": "fj", "label": "Fijian" },
  { "code": "fo", "label": "Faroese" },
  { "code": "fr", "label": "French" },
  { "code": "fy", "label": "Western Frisian" },
  { "code": "ga", "label": "Irish" },
  { "code": "gd", "label": "Gaelic; Scomttish Gaelic" },
  { "code": "gl", "label": "Galician" },
  { "code": "gn", "label": "Guarani" },
  { "code": "gu", "label": "Gujarati" },
  { "code": "gv", "label": "Manx" },
  { "code": "ha", "label": "Hausa" },
  { "code": "he", "label": "Hebrew" },
  { "code": "hi", "label": "Hindi" },
  { "code": "ho", "label": "Hiri Motu" },
  { "code": "hr", "label": "Croatian" },
  { "code": "ht", "label": "Haitian; Haitian Creole" },
  { "code": "hu", "label": "Hungarian" },
  { "code": "hy", "label": "Armenian" },
  { "code": "hz", "label": "Herero" },
  {
    "code": "ia",
    "label": "Interlingua (International Auxiliary Language Association)"
  },
  { "code": "id", "label": "Indonesian" },
  { "code": "ie", "label": "Interlingue; Occidental" },
  { "code": "ig", "label": "Igbo" },
  { "code": "ii", "label": "Sichuan Yi; Nuosu" },
  { "code": "ik", "label": "Inupiaq" },
  { "code": "io", "label": "Ido" },
  { "code": "is", "label": "Icelandic" },
  { "code": "it", "label": "Italian" },
  { "code": "iu", "label": "Inuktitut" },
  { "code": "ja", "label": "Japanese" },
  { "code": "jv", "label": "Javanese" },
  { "code": "ka", "label": "Georgian" },
  { "code": "kg", "label": "Kongo" },
  { "code": "ki", "label": "Kikuyu; Gikuyu" },
  { "code": "kj", "label": "Kuanyama; Kwanyama" },
  { "code": "kk", "label": "Kazakh" },
  { "code": "kl", "label": "Kalaallisut; Greenlandic" },
  { "code": "km", "label": "Central Khmer" },
  { "code": "kn", "label": "Kannada" },
  { "code": "ko", "label": "Korean" },
  { "code": "kr", "label": "Kanuri" },
  { "code": "ks", "label": "Kashmiri" },
  { "code": "ku", "label": "Kurdish" },
  { "code": "kv", "label": "Komi" },
  { "code": "kw", "label": "Cornish" },
  { "code": "ky", "label": "Kirghiz; Kyrgyz" },
  { "code": "la", "label": "Latin" },
  { "code": "lb", "label": "Luxembourgish; Letzeburgesch" },
  { "code": "lg", "label": "Ganda" },
  { "code": "li", "label": "Limburgan; Limburger; Limburgish" },
  { "code": "ln", "label": "Lingala" },
  { "code": "lo", "label": "Lao" },
  { "code": "lt", "label": "Lithuanian" },
  { "code": "lu", "label": "Luba-Katanga" },
  { "code": "lv", "label": "Latvian" },
  { "code": "mg", "label": "Malagasy" },
  { "code": "mh", "label": "Marshallese" },
  { "code": "mi", "label": "Maori" },
  { "code": "mk", "label": "Macedonian" },
  { "code": "ml", "label": "Malayalam" },
  { "code": "mn", "label": "Mongolian" },
  { "code": "mr", "label": "Marathi" },
  { "code": "ms", "label": "Malay" },
  { "code": "mt", "label": "Maltese" },
  { "code": "my", "label": "Burmese" },
  { "code": "na", "label": "Nauru" },
  {
    "code": "nb",
    "label": "Bokmål, Norwegian; Norwegian Bokmål"
  },
  { "code": "nd", "label": "Ndebele, North; North Ndebele" },
  { "code": "ne", "label": "Nepali" },
  { "code": "ng", "label": "Ndonga" },
  { "code": "nl", "label": "Dutch; Flemish" },
  { "code": "nn", "label": "Norwegian Nynorsk; Nynorsk, Norwegian" },
  { "code": "no", "label": "Norwegian" },
  { "code": "nr", "label": "Ndebele, South; South Ndebele" },
  { "code": "nv", "label": "Navajo; Navaho" },
  { "code": "ny", "label": "Chichewa; Chewa; Nyanja" },
  { "code": "oc", "label": "Occitan (post 1500)" },
  { "code": "oj", "label": "Ojibwa" },
  { "code": "om", "label": "Oromo" },
  { "code": "or", "label": "Oriya" },
  { "code": "os", "label": "Ossetian; Ossetic" },
  { "code": "pa", "label": "Panjabi; Punjabi" },
  { "code": "pi", "label": "Pali" },
  { "code": "pl", "label": "Polish" },
  { "code": "ps", "label": "Pushto; Pashto" },
  { "code": "pt", "label": "Portuguese" },
  { "code": "qu", "label": "Quechua" },
  { "code": "rm", "label": "Romansh" },
  { "code": "rn", "label": "Rundi" },
  { "code": "ro", "label": "Romanian; Moldavian; Moldovan" },
  { "code": "ru", "label": "Russian" },
  { "code": "rw", "label": "Kinyarwanda" },
  { "code": "sa", "label": "Sanskrit" },
  { "code": "sc", "label": "Sardinian" },
  { "code": "sd", "label": "Sindhi" },
  { "code": "se", "label": "Northern Sami" },
  { "code": "sg", "label": "Sango" },
  { "code": "si", "label": "Sinhala; Sinhalese" },
  { "code": "sk", "label": "Slovak" },
  { "code": "sl", "label": "Slovenian" },
  { "code": "sm", "label": "Samoan" },
  { "code": "sn", "label": "Shona" },
  { "code": "so", "label": "Somali" },
  { "code": "sq", "label": "Albanian" },
  { "code": "sr", "label": "Serbian" },
  { "code": "ss", "label": "Swati" },
  { "code": "st", "label": "Sotho, Southern" },
  { "code": "su", "label": "Sundanese" },
  { "code": "sv", "label": "Swedish" },
  { "code": "sw", "label": "Swahili" },
  { "code": "ta", "label": "Tamil" },
  { "code": "te", "label": "Telugu" },
  { "code": "tg", "label": "Tajik" },
  { "code": "th", "label": "Thai" },
  { "code": "ti", "label": "Tigrinya" },
  { "code": "tk", "label": "Turkmen" },
  { "code": "tl", "label": "Tagalog" },
  { "code": "tn", "label": "Tswana" },
  { "code": "to", "label": "Tonga (Tonga Islands)" },
  { "code": "tr", "label": "Turkish" },
  { "code": "ts", "label": "Tsonga" },
  { "code": "tt", "label": "Tatar" },
  { "code": "tw", "label": "Twi" },
  { "code": "ty", "label": "Tahitian" },
  { "code": "ug", "label": "Uighur; Uyghur" },
  { "code": "uk", "label": "Ukrainian" },
  { "code": "ur", "label": "Urdu" },
  { "code": "uz", "label": "Uzbek" },
  { "code": "ve", "label": "Venda" },
  { "code": "vi", "label": "Vietnamese" },
  { "code": "vo", "label": "Volapük" },
  { "code": "wa", "label": "Walloon" },
  { "code": "wo", "label": "Wolof" },
  { "code": "xh", "label": "Xhosa" },
  { "code": "yi", "label": "Yiddish" },
  { "code": "yo", "label": "Yoruba" },
  { "code": "za", "label": "Zhuang; Chuang" },
  { "code": "zh", "label": "Chinese" },
  { "code": "zu", "label": "Zulu" }
]

export const TimezonesConst = [
  {
    "code": "Dateline Standard Time",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "label": "(UTC-12:00) International Date Line West",
    "utc": [
      "Etc/GMT+12"
    ]
  },
  {
    "code": "UTC-11",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "label": "(UTC-11:00) Coordinated Universal Time-11",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  {
    "code": "Hawaiian Standard Time",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "label": "(UTC-10:00) Hawaii",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    "code": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "label": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "code": "Pacific Standard Time (Mexico)",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "label": "(UTC-08:00) Baja California",
    "utc": [
      "America/Santa_Isabel"
    ]
  },
  {
    "code": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "label": "(UTC-07:00) Pacific Daylight Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver"
    ]
  },
  {
    "code": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "label": "(UTC-08:00) Pacific Standard Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT"
    ]
  },
  {
    "code": "US Mountain Standard Time",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "label": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7"
    ]
  },
  {
    "code": "Mountain Standard Time (Mexico)",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "label": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "utc": [
      "America/Chihuahua",
      "America/Mazatlan"
    ]
  },
  {
    "code": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "label": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "code": "Central America Standard Time",
    "abbr": "CAST",
    "offset": -6,
    "isdst": false,
    "label": "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    "code": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "label": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "code": "Central Standard Time (Mexico)",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "utc": [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ]
  },
  {
    "code": "Canada Central Standard Time",
    "abbr": "CCST",
    "offset": -6,
    "isdst": false,
    "label": "(UTC-06:00) Saskatchewan",
    "utc": [
      "America/Regina",
      "America/Swift_Current"
    ]
  },
  {
    "code": "SA Pacific Standard Time",
    "abbr": "SPST",
    "offset": -5,
    "isdst": false,
    "label": "(UTC-05:00) Bogota, Lima, Quito",
    "utc": [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ]
  },
  {
    "code": "Eastern Standard Time",
    "abbr": "EST",
    "offset": -5,
    "isdst": false,
    "label": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ]
  },
  {
    "code": "Eastern Daylight Time",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "label": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ]
  },
  {
    "code": "US Eastern Standard Time",
    "abbr": "UEDT",
    "offset": -5,
    "isdst": false,
    "label": "(UTC-05:00) Indiana (East)",
    "utc": [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ]
  },
  {
    "code": "Venezuela Standard Time",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "label": "(UTC-04:30) Caracas",
    "utc": [
      "America/Caracas"
    ]
  },
  {
    "code": "Paraguay Standard Time",
    "abbr": "PYT",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Asuncion",
    "utc": [
      "America/Asuncion"
    ]
  },
  {
    "code": "Atlantic Standard Time",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "label": "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "code": "Central Brazilian Standard Time",
    "abbr": "CBST",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Cuiaba",
    "utc": [
      "America/Campo_Grande",
      "America/Cuiaba"
    ]
  },
  {
    "code": "SA Western Standard Time",
    "abbr": "SWST",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "utc": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ]
  },
  {
    "code": "Pacific SA Standard Time",
    "abbr": "PSST",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Santiago",
    "utc": [
      "America/Santiago",
      "Antarctica/Palmer"
    ]
  },
  {
    "code": "Newfoundland Standard Time",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "label": "(UTC-03:30) Newfoundland",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "code": "E. South America Standard Time",
    "abbr": "ESAST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Brasilia",
    "utc": [
      "America/Sao_Paulo"
    ]
  },
  {
    "code": "Argentina Standard Time",
    "abbr": "AST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Buenos Aires",
    "utc": [
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    "code": "SA Eastern Standard Time",
    "abbr": "SEST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Cayenne, Fortaleza",
    "utc": [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ]
  },
  {
    "code": "Greenland Standard Time",
    "abbr": "GDT",
    "offset": -3,
    "isdst": true,
    "label": "(UTC-03:00) Greenland",
    "utc": [
      "America/Godthab"
    ]
  },
  {
    "code": "Montevideo Standard Time",
    "abbr": "MST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Montevideo",
    "utc": [
      "America/Montevideo"
    ]
  },
  {
    "code": "Bahia Standard Time",
    "abbr": "BST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Salvador",
    "utc": [
      "America/Bahia"
    ]
  },
  {
    "code": "UTC-02",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "label": "(UTC-02:00) Coordinated Universal Time-02",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ]
  },
  {
    "code": "Mid-Atlantic Standard Time",
    "abbr": "MDT",
    "offset": -1,
    "isdst": true,
    "label": "(UTC-02:00) Mid-Atlantic - Old",
    "utc": []
  },
  {
    "code": "Azores Standard Time",
    "abbr": "ADT",
    "offset": 0,
    "isdst": true,
    "label": "(UTC-01:00) Azores",
    "utc": [
      "America/Scoresbysund",
      "Atlantic/Azores"
    ]
  },
  {
    "code": "Cape Verde Standard Time",
    "abbr": "CVST",
    "offset": -1,
    "isdst": false,
    "label": "(UTC-01:00) Cape Verde Is.",
    "utc": [
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ]
  },
  {
    "code": "Morocco Standard Time",
    "abbr": "MDT",
    "offset": 1,
    "isdst": true,
    "label": "(UTC) Casablanca",
    "utc": [
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ]
  },
  {
    "code": "UTC",
    "abbr": "UTC",
    "offset": 0,
    "isdst": false,
    "label": "(UTC) Coordinated Universal Time",
    "utc": [
      "America/Danmarkshavn",
      "Etc/GMT"
    ]
  },
  {
    "code": "GMT Standard Time",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "label": "(UTC) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "code": "British Summer Time",
    "abbr": "BST",
    "offset": 1,
    "isdst": true,
    "label": "(UTC+01:00) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "code": "GMT Standard Time",
    "abbr": "GDT",
    "offset": 1,
    "isdst": true,
    "label": "(UTC) Dublin, Lisbon",
    "utc": [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon"
    ]
  },
  {
    "code": "Greenwich Standard Time",
    "abbr": "GST",
    "offset": 0,
    "isdst": false,
    "label": "(UTC) Monrovia, Reykjavik",
    "utc": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ]
  },
  {
    "code": "W. Europe Standard Time",
    "abbr": "WEDT",
    "offset": 2,
    "isdst": true,
    "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "utc": [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ]
  },
  {
    "code": "Central Europe Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "utc": [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ]
  },
  {
    "code": "Romance Standard Time",
    "abbr": "RDT",
    "offset": 2,
    "isdst": true,
    "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "utc": [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ]
  },
  {
    "code": "Central European Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "utc": [
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Warsaw",
      "Europe/Zagreb"
    ]
  },
  {
    "code": "W. Central Africa Standard Time",
    "abbr": "WCAST",
    "offset": 1,
    "isdst": false,
    "label": "(UTC+01:00) West Central Africa",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    "code": "Namibia Standard Time",
    "abbr": "NST",
    "offset": 1,
    "isdst": false,
    "label": "(UTC+01:00) Windhoek",
    "utc": [
      "Africa/Windhoek"
    ]
  },
  {
    "code": "GTB Standard Time",
    "abbr": "GDT",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Athens, Bucharest",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ]
  },
  {
    "code": "Middle East Standard Time",
    "abbr": "MEDT",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Beirut",
    "utc": [
      "Asia/Beirut"
    ]
  },
  {
    "code": "Egypt Standard Time",
    "abbr": "EST",
    "offset": 2,
    "isdst": false,
    "label": "(UTC+02:00) Cairo",
    "utc": [
      "Africa/Cairo"
    ]
  },
  {
    "code": "Syria Standard Time",
    "abbr": "SDT",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Damascus",
    "utc": [
      "Asia/Damascus"
    ]
  },
  {
    "code": "E. Europe Standard Time",
    "abbr": "EEDT",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) E. Europe",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"

    ]
  },
  {
    "code": "South Africa Standard Time",
    "abbr": "SAST",
    "offset": 2,
    "isdst": false,
    "label": "(UTC+02:00) Harare, Pretoria",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ]
  },
  {
    "code": "FLE Standard Time",
    "abbr": "FDT",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "utc": [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ]
  },
  {
    "code": "Turkey Standard Time",
    "abbr": "TDT",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Istanbul",
    "utc": [
      "Europe/Istanbul"
    ]
  },
  {
    "code": "Israel Standard Time",
    "abbr": "JDT",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Jerusalem",
    "utc": [
      "Asia/Jerusalem"
    ]
  },
  {
    "code": "Libya Standard Time",
    "abbr": "LST",
    "offset": 2,
    "isdst": false,
    "label": "(UTC+02:00) Tripoli",
    "utc": [
      "Africa/Tripoli"
    ]
  },
  {
    "code": "Jordan Standard Time",
    "abbr": "JST",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Amman",
    "utc": [
      "Asia/Amman"
    ]
  },
  {
    "code": "Arabic Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Baghdad",
    "utc": [
      "Asia/Baghdad"
    ]
  },
  {
    "code": "Kaliningrad Standard Time",
    "abbr": "KST",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+02:00) Kaliningrad",
    "utc": [
      "Europe/Kaliningrad"
    ]
  },
  {
    "code": "Arab Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Kuwait, Riyadh",
    "utc": [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ]
  },
  {
    "code": "E. Africa Standard Time",
    "abbr": "EAST",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Nairobi",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ]
  },
  {
    "code": "Moscow Standard Time",
    "abbr": "MSK",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    "utc": [
	    "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk"
    ]
  },
  {
    "code": "Samara Time",
    "abbr": "SAMT",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": [
	    "Europe/Astrakhan",
      "Europe/Samara",
	    "Europe/Ulyanovsk"
    ]
  },
  {
    "code": "Iran Standard Time",
    "abbr": "IDT",
    "offset": 4.5,
    "isdst": true,
    "label": "(UTC+03:30) Tehran",
    "utc": [
      "Asia/Tehran"
    ]
  },
  {
    "code": "Arabian Standard Time",
    "abbr": "AST",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Abu Dhabi, Muscat",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "code": "Azerbaijan Standard Time",
    "abbr": "ADT",
    "offset": 5,
    "isdst": true,
    "label": "(UTC+04:00) Baku",
    "utc": [
      "Asia/Baku"
    ]
  },
  {
    "code": "Mauritius Standard Time",
    "abbr": "MST",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Port Louis",
    "utc": [
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ]
  },
  {
    "code": "Georgian Standard Time",
    "abbr": "GET",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Tbilisi",
    "utc": [
      "Asia/Tbilisi"
    ]
  },
  {
    "code": "Caucasus Standard Time",
    "abbr": "CST",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Yerevan",
    "utc": [
      "Asia/Yerevan"
    ]
  },
  {
    "code": "Afghanistan Standard Time",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "label": "(UTC+04:30) Kabul",
    "utc": [
      "Asia/Kabul"
    ]
  },
  {
    "code": "West Asia Standard Time",
    "abbr": "WAST",
    "offset": 5,
    "isdst": false,
    "label": "(UTC+05:00) Ashgabat, Tashkent",
    "utc": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ]
  },
  {
    "code": "Yekaterinburg Time",
    "abbr": "YEKT",
    "offset": 5,
    "isdst": false,
    "label": "(UTC+05:00) Yekaterinburg",
    "utc": [
      "Asia/Yekaterinburg"
    ]
  },
  {
    "code": "Pakistan Standard Time",
    "abbr": "PKT",
    "offset": 5,
    "isdst": false,
    "label": "(UTC+05:00) Islamabad, Karachi",
    "utc": [
      "Asia/Karachi"
    ]
  },
  {
    "code": "India Standard Time",
    "abbr": "IST",
    "offset": 5.5,
    "isdst": false,
    "label": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": [
      "Asia/Kolkata",
      "Asia/Calcutta"
    ]
  },
  {
    "code": "Sri Lanka Standard Time",
    "abbr": "SLST",
    "offset": 5.5,
    "isdst": false,
    "label": "(UTC+05:30) Sri Jayawardenepura",
    "utc": [
      "Asia/Colombo"
    ]
  },
  {
    "code": "Nepal Standard Time",
    "abbr": "NST",
    "offset": 5.75,
    "isdst": false,
    "label": "(UTC+05:45) Kathmandu",
    "utc": [
      "Asia/Kathmandu"
    ]
  },
  {
    "code": "Central Asia Standard Time",
    "abbr": "CAST",
    "offset": 6,
    "isdst": false,
    "label": "(UTC+06:00) Nur-Sultan (Astana)",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    "code": "Bangladesh Standard Time",
    "abbr": "BST",
    "offset": 6,
    "isdst": false,
    "label": "(UTC+06:00) Dhaka",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "code": "Myanmar Standard Time",
    "abbr": "MST",
    "offset": 6.5,
    "isdst": false,
    "label": "(UTC+06:30) Yangon (Rangoon)",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "code": "SE Asia Standard Time",
    "abbr": "SAST",
    "offset": 7,
    "isdst": false,
    "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "utc": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ]
  },
  {
    "code": "N. Central Asia Standard Time",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "label": "(UTC+07:00) Novosibirsk",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ]
  },
  {
    "code": "China Standard Time",
    "abbr": "CST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "utc": [
      "Asia/Hong_Kong",
      "Asia/Macau",
      "Asia/Shanghai"
    ]
  },
  {
    "code": "North Asia Standard Time",
    "abbr": "NAST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Krasnoyarsk",
    "utc": [
      "Asia/Krasnoyarsk"
    ]
  },
  {
    "code": "Singapore Standard Time",
    "abbr": "MPST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Kuala Lumpur, Singapore",
    "utc": [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ]
  },
  {
    "code": "W. Australia Standard Time",
    "abbr": "WAST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Perth",
    "utc": [
      "Antarctica/Casey",
      "Australia/Perth"
    ]
  },
  {
    "code": "Taipei Standard Time",
    "abbr": "TST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Taipei",
    "utc": [
      "Asia/Taipei"
    ]
  },
  {
    "code": "Ulaanbaatar Standard Time",
    "abbr": "UST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Ulaanbaatar",
    "utc": [
      "Asia/Choibalsan",
      "Asia/Ulaanbaatar"
    ]
  },
  {
    "code": "North Asia East Standard Time",
    "abbr": "NAEST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Irkutsk",
    "utc": [
      "Asia/Irkutsk"
    ]
  },
  {
    "code": "Japan Standard Time",
    "abbr": "JST",
    "offset": 9,
    "isdst": false,
    "label": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ]
  },
  {
    "code": "Korea Standard Time",
    "abbr": "KST",
    "offset": 9,
    "isdst": false,
    "label": "(UTC+09:00) Seoul",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ]
  },
  {
    "code": "Cen. Australia Standard Time",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "label": "(UTC+09:30) Adelaide",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "code": "AUS Central Standard Time",
    "abbr": "ACST",
    "offset": 9.5,
    "isdst": false,
    "label": "(UTC+09:30) Darwin",
    "utc": [
      "Australia/Darwin"
    ]
  },
  {
    "code": "E. Australia Standard Time",
    "abbr": "EAST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Brisbane",
    "utc": [
      "Australia/Brisbane",
      "Australia/Lindeman"
    ]
  },
  {
    "code": "AUS Eastern Standard Time",
    "abbr": "AEST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": [
      "Australia/Melbourne",
      "Australia/Sydney"
    ]
  },
  {
    "code": "West Pacific Standard Time",
    "abbr": "WPST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Guam, Port Moresby",
    "utc": [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ]
  },
  {
    "code": "Tasmania Standard Time",
    "abbr": "TST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Hobart",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ]
  },
  {
    "code": "Yakutsk Standard Time",
    "abbr": "YST",
    "offset": 9,
    "isdst": false,
    "label": "(UTC+09:00) Yakutsk",
    "utc": [
      "Asia/Chita",
      "Asia/Khandyga",
      "Asia/Yakutsk"
    ]
  },
  {
    "code": "Central Pacific Standard Time",
    "abbr": "CPST",
    "offset": 11,
    "isdst": false,
    "label": "(UTC+11:00) Solomon Is., New Caledonia",
    "utc": [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ]
  },
  {
    "code": "Vladivostok Standard Time",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "label": "(UTC+11:00) Vladivostok",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ]
  },
  {
    "code": "New Zealand Standard Time",
    "abbr": "NZST",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Auckland, Wellington",
    "utc": [
      "Antarctica/McMurdo",
      "Pacific/Auckland"
    ]
  },
  {
    "code": "UTC+12",
    "abbr": "U",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Coordinated Universal Time+12",
    "utc": [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "code": "Fiji Standard Time",
    "abbr": "FST",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Fiji",
    "utc": [
      "Pacific/Fiji"
    ]
  },
  {
    "code": "Magadan Standard Time",
    "abbr": "MST",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Magadan",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "code": "Kamchatka Standard Time",
    "abbr": "KDT",
    "offset": 13,
    "isdst": true,
    "label": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "utc": [
      "Asia/Kamchatka"
    ]
  },
  {
    "code": "Tonga Standard Time",
    "abbr": "TST",
    "offset": 13,
    "isdst": false,
    "label": "(UTC+13:00) Nuku'alofa",
    "utc": [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ]
  },
  {
    "code": "Samoa Standard Time",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "label": "(UTC+13:00) Samoa",
    "utc": [
      "Pacific/Apia"
    ]
  }
]

export const CurrenciesConst = [
  {code: 'AED', symbol: 'د.إ;', label: 'AED - UAE dirham'},
  {code: 'AFN', symbol: 'Afs', label: 'AFN - Afghan afghani'},
  {code: 'ALL', symbol: 'L', label: 'ALL - Albanian lek'},
  {code: 'AMD', symbol: 'AMD', label: 'AMD - Armenian dram'},
  {code: 'ANG', symbol: 'NAƒ', label: 'ANG - Netherlands Antillean gulden'},
  {code: 'AOA', symbol: 'Kz', label: 'AOA - Angolan kwanza'},
  {code: 'ARS', symbol: '$', label: 'ARS - Argentine peso'},
  {code: 'AUD', symbol: '$', label: 'AUD - Australian dollar'},
  {code: 'AWG', symbol: 'ƒ', label: 'AWG - Aruban florin'},
  {code: 'AZN', symbol: 'AZN', label: 'AZN - Azerbaijani manat'},
  {code: 'BAM', symbol: 'KM', label: 'BAM - Bosnia and Herzegovina konvertibilna marka'},
  {code: 'BBD', symbol: 'Bds$', label: 'BBD - Barbadian dollar'},
  {code: 'BDT', symbol: '৳', label: 'BDT - Bangladeshi taka'},
  {code: 'BGN', symbol: 'BGN', label: 'BGN - Bulgarian lev'},
  {code: 'BHD', symbol: '.د.ب', label: 'BHD - Bahraini dinar'},
  {code: 'BIF', symbol: 'FBu', label: 'BIF - Burundi franc'},
  {code: 'BMD', symbol: 'BD$', label: 'BMD - Bermudian dollar'},
  {code: 'BND', symbol: 'B$', label: 'BND - Brunei dollar'},
  {code: 'BOB', symbol: 'Bs.', label: 'BOB - Bolivian boliviano'},
  {code: 'BRL', symbol: 'R$', label: 'BRL - Brazilian real'},
  {code: 'BSD', symbol: 'B$', label: 'BSD - Bahamian dollar'},
  {code: 'BTN', symbol: 'Nu.', label: 'BTN - Bhutanese ngultrum'},
  {code: 'BWP', symbol: 'P', label: 'BWP - Botswana pula'},
  {code: 'BYR', symbol: 'Br', label: 'BYR - Belarusian ruble'},
  {code: 'BZD', symbol: 'BZ$', label: 'BZD - Belize dollar'},
  {code: 'CAD', symbol: '$', label: 'CAD - Canadian dollar'},
  {code: 'CDF', symbol: 'F', label: 'CDF - Congolese franc'},
  {code: 'CHF', symbol: 'Fr.', label: 'CHF - Swiss franc'},
  {code: 'CLP', symbol: '$', label: 'CLP - Chilean peso'},
  {code: 'CNY', symbol: '¥', label: 'CNY - Chinese/Yuan renminbi'},
  {code: 'COP', symbol: 'Col$', label: 'COP - Colombian peso'},
  {code: 'CRC', symbol: '₡', label: 'CRC - Costa Rican colon'},
  {code: 'CUC', symbol: '$', label: 'CUC - Cuban peso'},
  {code: 'CVE', symbol: 'Esc', label: 'CVE - Cape Verdean escudo'},
  {code: 'CZK', symbol: 'Kč', label: 'CZK - Czech koruna'},
  {code: 'DJF', symbol: 'Fdj', label: 'DJF - Djiboutian franc'},
  {code: 'DKK', symbol: 'Kr', label: 'DKK - Danish krone'},
  {code: 'DOP', symbol: 'RD$', label: 'DOP - Dominican peso'},
  {code: 'DZD', symbol: 'د.ج', label: 'DZD - Algerian dinar'},
  {code: 'EEK', symbol: 'KR', label: 'EEK - Estonian kroon'},
  {code: 'EGP', symbol: '£', label: 'EGP - Egyptian pound'},
  {code: 'ERN', symbol: 'Nfa', label: 'ERN - Eritrean nakfa'},
  {code: 'ETB', symbol: 'Br', label: 'ETB - Ethiopian birr'},
  {code: 'EUR', symbol: '€', label: 'EUR - European Euro'},
  {code: 'FJD', symbol: 'FJ$', label: 'FJD - Fijian dollar'},
  {code: 'FKP', symbol: '£', label: 'FKP - Falkland Islands pound'},
  {code: 'GBP', symbol: '£', label: 'GBP - British pound'},
  {code: 'GEL', symbol: 'GEL', label: 'GEL - Georgian lari'},
  {code: 'GHS', symbol: 'GH₵', label: 'GHS - Ghanaian cedi'},
  {code: 'GIP', symbol: '£', label: 'GIP - Gibraltar pound'},
  {code: 'GMD', symbol: 'D', label: 'GMD - Gambian dalasi'},
  {code: 'GNF', symbol: 'FG', label: 'GNF - Guinean franc'},
  {code: 'GQE', symbol: 'CFA', label: 'GQE - Central African CFA franc'},
  {code: 'GTQ', symbol: 'Q', label: 'GTQ - Guatemalan quetzal'},
  {code: 'GYD', symbol: 'GY$', label: 'GYD - Guyanese dollar'},
  {code: 'HKD', symbol: 'HK$', label: 'HKD - Hong Kong dollar'},
  {code: 'HNL', symbol: 'L', label: 'HNL - Honduran lempira'},
  {code: 'HRK', symbol: 'kn', label: 'HRK - Croatian kuna'},
  {code: 'HTG', symbol: 'G', label: 'HTG - Haitian gourde'},
  {code: 'HUF', symbol: 'Ft', label: 'HUF - Hungarian forint'},
  {code: 'IDR', symbol: 'Rp', label: 'IDR - Indonesian rupiah'},
  {code: 'ILS', symbol: '₪', label: 'ILS - Israeli new sheqel'},
  {code: 'INR', symbol: '₹', label: 'INR - Indian rupee'},
  {code: 'IQD', symbol: 'د.ع', label: 'IQD - Iraqi dinar'},
  {code: 'IRR', symbol: 'IRR', label: 'IRR - Iranian rial'},
  {code: 'ISK', symbol: 'kr', label: 'ISK - Icelandic króna'},
  {code: 'JMD', symbol: 'J$', label: 'JMD - Jamaican dollar'},
  {code: 'JOD', symbol: 'JOD', label: 'JOD - Jordanian dinar'},
  {code: 'JPY', symbol: '¥', label: 'JPY - Japanese yen'},
  {code: 'KES', symbol: 'KSh', label: 'KES - Kenyan shilling'},
  {code: 'KGS', symbol: 'сом', label: 'KGS - Kyrgyzstani som'},
  {code: 'KHR', symbol: '៛', label: 'KHR - Cambodian riel'},
  {code: 'KMF', symbol: 'KMF', label: 'KMF - Comorian franc'},
  {code: 'KPW', symbol: 'W', label: 'KPW - North Korean won'},
  {code: 'KRW', symbol: 'W', label: 'KRW - South Korean won'},
  {code: 'KWD', symbol: 'KWD', label: 'KWD - Kuwaiti dinar'},
  {code: 'KYD', symbol: 'KY$', label: 'KYD - Cayman Islands dollar'},
  {code: 'KZT', symbol: 'T', label: 'KZT - Kazakhstani tenge'},
  {code: 'LAK', symbol: 'KN', label: 'LAK - Lao kip'},
  {code: 'LBP', symbol: '£', label: 'LBP - Lebanese lira'},
  {code: 'LKR', symbol: 'Rs', label: 'LKR - Sri Lankan rupee'},
  {code: 'LRD', symbol: 'L$', label: 'LRD - Liberian dollar'},
  {code: 'LSL', symbol: 'M', label: 'LSL - Lesotho loti'},
  {code: 'LTL', symbol: 'Lt', label: 'LTL - Lithuanian litas'},
  {code: 'LVL', symbol: 'Ls', label: 'LVL - Latvian lats'},
  {code: 'LYD', symbol: 'LD', label: 'LYD - Libyan dinar'},
  {code: 'MAD', symbol: 'MAD', label: 'MAD - Moroccan dirham'},
  {code: 'MDL', symbol: 'MDL', label: 'MDL - Moldovan leu'},
  {code: 'MGA', symbol: 'FMG', label: 'MGA - Malagasy ariary'},
  {code: 'MKD', symbol: 'MKD', label: 'MKD - Macedonian denar'},
  {code: 'MMK', symbol: 'K', label: 'MMK - Myanma kyat'},
  {code: 'MNT', symbol: '₮', label: 'MNT - Mongolian tugrik'},
  {code: 'MOP', symbol: 'P', label: 'MOP - Macanese pataca'},
  {code: 'MRO', symbol: 'UM', label: 'MRO - Mauritanian ouguiya'},
  {code: 'MUR', symbol: 'Rs', label: 'MUR - Mauritian rupee'},
  {code: 'MVR', symbol: 'Rf', label: 'MVR - Maldivian rufiyaa'},
  {code: 'MWK', symbol: 'MK', label: 'MWK - Malawian kwacha'},
  {code: 'MXN', symbol: '$', label: 'MXN - Mexican peso'},
  {code: 'MYR', symbol: 'RM', label: 'MYR - Malaysian ringgit'},
  {code: 'MZM', symbol: 'MTn', label: 'MZM - Mozambican metical'},
  {code: 'NAD', symbol: 'N$', label: 'NAD - Namibian dollar'},
  {code: 'NGN', symbol: '₦', label: 'NGN - Nigerian naira'},
  {code: 'NIO', symbol: 'C$', label: 'NIO - Nicaraguan córdoba'},
  {code: 'NOK', symbol: 'kr', label: 'NOK - Norwegian krone'},
  {code: 'NPR', symbol: 'NRs', label: 'NPR - Nepalese rupee'},
  {code: 'NZD', symbol: 'NZ$', label: 'NZD - New Zealand dollar'},
  {code: 'OMR', symbol: 'OMR', label: 'OMR - Omani rial'},
  {code: 'PAB', symbol: 'B./', label: 'PAB - Panamanian balboa'},
  {code: 'PEN', symbol: 'S/.', label: 'PEN - Peruvian nuevo sol'},
  {code: 'PGK', symbol: 'K', label: 'PGK - Papua New Guinean kina'},
  {code: 'PHP', symbol: '₱', label: 'PHP - Philippine peso'},
  {code: 'PKR', symbol: 'Rs.', label: 'PKR - Pakistani rupee'},
  {code: 'PLN', symbol: 'zł', label: 'PLN - Polish zloty'},
  {code: 'PYG', symbol: '₲', label: 'PYG - Paraguayan guarani'},
  {code: 'QAR', symbol: 'QR', label: 'QAR - Qatari riyal'},
  {code: 'RON', symbol: 'L', label: 'RON - Romanian leu'},
  {code: 'RSD', symbol: 'din.', label: 'RSD - Serbian dinar'},
  {code: 'RUB', symbol: 'R', label: 'RUB - Russian ruble'},
  {code: 'SAR', symbol: 'SR', label: 'SAR - Saudi riyal'},
  {code: 'SBD', symbol: 'SI$', label: 'SBD - Solomon Islands dollar'},
  {code: 'SCR', symbol: 'SR', label: 'SCR - Seychellois rupee'},
  {code: 'SDG', symbol: 'SDG', label: 'SDG - Sudanese pound'},
  {code: 'SEK', symbol: 'kr', label: 'SEK - Swedish krona'},
  {code: 'SGD', symbol: 'S$', label: 'SGD - Singapore dollar'},
  {code: 'SHP', symbol: '£', label: 'SHP - Saint Helena pound'},
  {code: 'SLL', symbol: 'Le', label: 'SLL - Sierra Leonean leone'},
  {code: 'SOS', symbol: 'Sh.', label: 'SOS - Somali shilling'},
  {code: 'SRD', symbol: '$', label: 'SRD - Surinamese dollar'},
  {code: 'SYP', symbol: 'LS', label: 'SYP - Syrian pound'},
  {code: 'SZL', symbol: 'E', label: 'SZL - Swazi lilangeni'},
  {code: 'THB', symbol: '฿', label: 'THB - Thai baht'},
  {code: 'TJS', symbol: 'TJS', label: 'TJS - Tajikistani somoni'},
  {code: 'TMT', symbol: 'm', label: 'TMT - Turkmen manat'},
  {code: 'TND', symbol: 'DT', label: 'TND - Tunisian dinar'},
  {code: 'TRY', symbol: 'TRY', label: 'TRY - Turkish new lira'},
  {code: 'TTD', symbol: 'TT$', label: 'TTD - Trinidad and Tobago dollar'},
  {code: 'TWD', symbol: 'NT$', label: 'TWD - New Taiwan dollar'},
  {code: 'TZS', symbol: 'TZS', label: 'TZS - Tanzanian shilling'},
  {code: 'UAH', symbol: 'UAH', label: 'UAH - Ukrainian hryvnia'},
  {code: 'UGX', symbol: 'USh', label: 'UGX - Ugandan shilling'},
  {code: 'USD', symbol: 'US$', label: 'USD - United States dollar'},
  {code: 'UYU', symbol: '$U', label: 'UYU - Uruguayan peso'},
  {code: 'UZS', symbol: 'UZS', label: 'UZS - Uzbekistani som'},
  {code: 'VEB', symbol: 'Bs', label: 'VEB - Venezuelan bolivar'},
  {code: 'VND', symbol: '₫', label: 'VND - Vietnamese dong'},
  {code: 'VUV', symbol: 'VT', label: 'VUV - Vanuatu vatu'},
  {code: 'WST', symbol: 'WS$', label: 'WST - Samoan tala'},
  {code: 'XAF', symbol: 'CFA', label: 'XAF - Central African CFA franc'},
  {code: 'XCD', symbol: 'EC$', label: 'XCD - East Caribbean dollar'},
  {code: 'XDR', symbol: 'SDR', label: 'XDR - Special Drawing Rights'},
  {code: 'XOF', symbol: 'CFA', label: 'XOF - West African CFA franc'},
  {code: 'XPF', symbol: 'F', label: 'XPF - CFP franc'},
  {code: 'YER', symbol: 'YER', label: 'YER - Yemeni rial'},
  {code: 'ZAR', symbol: 'R', label: 'ZAR - South African rand'},
  {code: 'ZMK', symbol: 'ZK', label: 'ZMK - Zambian kwacha'},
  {code: 'ZWR', symbol: 'Z$', label: 'ZWR - Zimbabwean dollar'}
]

export const DisplayLanguagesConst = [
  { "code": "HE", "label": "Hebrew" },
  { "code": "EN", "label": "English" },
  { "code": "AR", "label": "Arabic" }
]

export const TagTypeConst = [
  { "code": "1", "label": "Supplier" },
  { "code": "2", "label": "Product" },
  { "code": "3", "label": "Business" },
  { "code": "4", "label": "Message" },
  { "code": "5", "label": "Tourism" },
  { "code": "6", "label": "Reseller Home Page" },
  { "code": "7", "label": "Shaniv Categories" },
  // { "code": "8", "label": "Customer Home Page" },
]

export const DISTRIBUTOR_ADMIN_ROLE_ID = 16;
export const DISTRIBUTOR_AGENT_ROLE_ID = 2;
