<template>
  <v-card class="mt-10">
    <div class="px-10 py-5 font-weight-bold border-bottom-gray">
      {{ $t("Sign-in Method") }}
    </div>
    <div class="px-10 py-8 fs14">
      <div class="d-flex justify-content-between flex-wrap" v-if="!isShowEditEmail">
        <div class="font-weight-bold">
          <p class="mb-0">
            {{ $t("Email") }}
          </p>
          <p class="mb-0 blue-grey--text text--darken-1">
            {{ user.email }}
          </p>
        </div>
        <v-btn
          class="text-none mx-10 mt-5"
          @click="toggleShowEditEmailForm(true)"
        >
          {{ $t("Change Email") }}
        </v-btn>
      </div>
      <div class="mb-10" v-else>
        <div class="row">
          <div class="col-sm-6">
            <p class="mb-2">{{ $t("Enter New Email Address") }}</p>
            <input 
              type="email" 
              class="input" 
              v-model="newEmail"
              :class="{ 'validation-error': $v.newEmail.$anyError }"
              @input="$v.newEmail.$touch"
            >
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newEmail.$anyError && !$v.newEmail.required">
              {{ $t('Required') }}
            </div>
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newEmail.$anyError && !$v.newEmail.email">
              {{ $t('Invalid value') }}
            </div>
          </div>
          <div class="col-sm-6">
            <p class="mb-2">{{ $t("Confirm Password") }}</p>
            <input type="password" class="input" v-model="confirmPassword">
          </div>
        </div>
        <div class="d-flex mt-5">
          <v-btn
            color="primary"
            class="text-none"
            :disabled="!newEmail.length || !confirmPassword.length"
            @click="handleUpdateEmail"
          >
            {{ $t("Update Email") }}
          </v-btn>
          <v-btn
            class="text-none mx-10"
            @click="toggleShowEditEmailForm(false)"
          >
            {{ $t("Cancel") }}
          </v-btn>
        </div>

      </div>

      <div class="d-flex justify-content-between flex-wrap mt-10" v-if="!isShowResetPassword">
        <div class="font-weight-bold">
          <p class="mb-0">
            {{ $t("Password") }}
          </p>
          <p class="mb-0 blue-grey--text text--darken-1">
            ************
          </p>
        </div>
        <v-btn
          class="text-none mx-10 mt-5"
          @click="toggleShowResetPassword(true)"
        >
          {{ $t("Reset Password") }}
        </v-btn>
      </div>
      <div class="mt-10" v-else>
        <div class="row">
          <div class="col-sm-6">
            <p class="mb-2">{{ $t("Current Password") }}</p>
            <input type="password" class="input" v-model="password">
          </div>
        </div>
        <p class="mt-10 mb-5 blue--text">{{ $t("We will send a new password to your email") }}</p>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="text-none"
            :disabled="!password.length"
            @click="handleResetPassword()"
          >
            {{ $t("Send") }}
          </v-btn>
          <v-btn
            class="text-none mx-10"
            @click="toggleShowResetPassword(false)"
          >
            {{ $t("Cancel") }}
          </v-btn>
        </div>
      </div>

      <v-alert
        outlined
        icon="mdi-shield-lock-outline"
        type="info"
        text
        class="fs14 p-5 mt-10"
      >
        <h6 class="mb-0"><b>{{ $t("Secure Your Account") }}</b></h6>
        {{ $t("Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code") }} 
      </v-alert>
    </div>

    <Modal2FA @confirmedCode="confirmedCode" ref="modal2FARef" userType="UpdateEmail"></Modal2FA>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { validationMixin } from "vuelidate";
  import { required, email } from "vuelidate/lib/validators";
  import Modal2FA from "@/components/auth/Modal2FA.vue";

  export default {
    name: "SigninMethodCard",
    mixins: [validationMixin],
    components: {
      Modal2FA
    },  
    props: {
      user: {
        type: Object,
        required: true,
        default: {}
      },
    },
    data() {
      return {
        isShowEditEmail: false,
        isShowResetPassword: false,
        password: '',
        newEmail: '',
        confirmPassword: ''
      }
    },
    validations: {
      newEmail: {
        required, email
      },
    },
    methods: {
      ...mapActions('end_user', {
        resetPassword: 'resetPassword',
        checkEmail: 'checkEmail',
        changeEmail: 'changeEmail',
      }),
      toggleShowEditEmailForm(val) {
        this.isShowEditEmail = val;
        this.newEmail = '';
        this.confirmPassword = '';
        this.$v.$reset();
      },
      toggleShowResetPassword(val) {
        this.password = '';
        this.isShowResetPassword = val;
      },
      handleResetPassword() {
        this.resetPassword({
          password: this.password
        });
      },
      handleUpdateEmail() {
        this.$v.newEmail.$touch();
        if (this.$v.newEmail.$anyError) return;

        const that = this;
        this.checkEmail({
          password: this.confirmPassword,
          email: this.newEmail
        }).then(res => {
          if (res && res.message.title == "Success") {
            that.openModal2FA();
          }
          return res;
        });
      },
      openModal2FA() {
        this.$refs.modal2FARef.openModal();
      },
      confirmedCode() {
        const that = this;
        this.changeEmail({
          newEmail: this.newEmail 
        }).then(() => {
          that.toggleShowEditEmailForm(false);
        })
      }
    }
  }
</script>