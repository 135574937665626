<template>
  <div class="p-7 payCard_block">
    <h6 class="font-weight-bold">
      Jon Dou 
    </h6>
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div class="d-flex align-items-center">
        <v-icon class="bank_icon blue-grey--text text--darken-1">
          mdi-bank
        </v-icon>
        <div class="blue-grey--text text--darken-1 mx-5">
          <p class="font-weight-bold">
            {{cardData.bank_name}}
          </p>
          <p class="fs11">
            ****{{cardData.account_number}}
          </p>
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-btn
          class="text-none mx-5"
          small
          @click="deleteCard"
        >
          {{ $t("Delete") }}
        </v-btn>
        <v-btn
          class="text-none"
          small
          @click="editCard"
        >
          {{ $t("Edit") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankCard',
  props: {
    cardData: {
      type: Object,
      required: true,
      default: {}
    },
  },
  methods: {
    deleteCard() {
      this.$emit('deleteCard', this.cardData.id);
    },
    editCard() {
      this.$emit('editCard', this.cardData);
    },
  }
}
</script>

<style lang="scss" scoped>
  .bank_icon {
    font-size: 40px;
  }
</style>
