<template>
	<modal
    :width="600"
    :adaptive="true"
    class="modal-2fa CEModal modal-scroll-bar"
    name="modal2Fa"
  >
	  <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
		<div class="fs20 grey lighten-3 px-12 py-9">
			{{ $t("Two-step authentication") }}
		</div>
		<div class="py-10 px-20">
      <div v-if="!isShowConfirm">
        <p class="fs14">
          {{ $t("We will text a verification code to the email or mobile number whenever you sign in to your account.") }}
        </p>
        <v-radio-group
          v-model="twoFAType"
          row
        >
          <v-radio
            :label="$t('Email')"
            value="email"
          ></v-radio>
          <v-radio
            :label="$t('Phone')"
            value="phone"
          ></v-radio>
        </v-radio-group>
        <div class="authorisation__name" v-if="twoFAType == 'email'">
          <label class="label" for="email">{{ $t("Email address") }}</label>
          <input
            id="email"
            v-model="email"
            v-model.lazy="$v.email.$model"
            class="input authorisation__input twofa-email"
            :class="{ 'validation-error': $v.email.$anyError }"
            type="text"
            @input="$v.email.$touch"
            @keyup.enter="handleSendCode(false)"
          />
          <div class="color-red1 fs12 valide-error-msg" v-if="$v.email.$anyError && !$v.email.required">
            {{ $t('Required') }}
          </div>
          <div class="color-red1 fs12 valide-error-msg" v-else-if="$v.email.$anyError && !$v.email.email">
            {{ $t('Invalid value') }}
          </div>
        </div>
        <div class="authorisation__name" v-else-if="twoFAType == 'phone'">
          <label class="label" for="phoneNumber">{{ $t("Phone number") }}</label>
          <VuePhoneNumberInput 
            v-model="phoneNumber"
            required
            :border-radius="0"
            default-country-code="IL"
            :translations="phoneInputTranslations"
            @update="onUpdatePhoneNumber"
            @keyup.enter="handleSendCode(false)"
          />
        </div>
      </div>
      <div class="pb-20" v-else>
        <p class="mb-20 fs16">
          {{ $t("Please confirm your account by entering the verify code sent to {TXT}", { TXT: codePos }) }}
        </p>
        <div class="mx-auto" style="max-width: 320px">
          <v-otp-input
            @finish="onComplete"
            v-model="verifyCode"
          ></v-otp-input>
        </div>
        <v-checkbox
          v-model="isRememberDevice"
          :label='$t("Remember this device for 90 days")'
          class="mt-10"
          hide-details
          v-if="userType !== 'UpdateEmail'"
        ></v-checkbox>
        <p class="fs14 mb-0 mt-20">
          {{ $t("It may task a minute to receive your code.") }}
        </p>
        <div class="d-flex fs14">
          <p class="mb-0">
            {{ $t("Haven't received it?") }}
          </p>
          <p class="px-2 color-blue2 pointer" @click="handleSendCode(true)">
            {{ $t("Resend a new code.") }}
          </p>
        </div>
      </div>
		</div>

		<div class="d-flex justify-content-end grey lighten-3 px-10 py-8"  v-if="!isShowConfirm">
			<v-btn
				color="light"
				elevation="1"
				large
				class="text-capitalize mx-10"
				@click="closeModal()"
			>
				{{ $t("Cancel") }}	
			</v-btn>
			<v-btn
				color="primary primary-btn-color"
				elevation="1"
				large
				class="text-capitalize"
				@click="handleSendCode(false)"
			>
				{{ $t("Verify") }}	
			</v-btn>
		</div>
	</modal>	
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import DeviceDetector from "device-detector-js";

export default {
	name: "Modal2FA",
	mixins: [validationMixin],
  components: {
    VuePhoneNumberInput
  },
  props: {
    userType: {
      type: String,
      default: 'Admin'
    }
  },
  computed: {
    ...mapState({
      user_id: state => state.account.user_id,
      endUser_id: state => state.end_user.user_id,
    }),
    codePos() {
      if (this.twoFAType == 'email') {
        return this.email.replace(/(.{2})(.*)(?=@)/,
        function(gp1, gp2, gp3) { 
          for(let i = 0; i < gp3.length; i++) { 
            gp2+= "*"; 
          } return gp2; 
        });
      } else {
        return this.phoneNumber.slice(0, 2) + this.phoneNumber.slice(2).replace(/.(?=...)/g, '*');
      }
    },
    userID() {
      if (this.userType == "EndUser") {
        return this.endUser_id;
      } else {
        return this.user_id;
      }
    },
  },
	data() {
		return {
      isRememberDevice: false,
      isShowConfirm: false,
			twoFAType: "email",
			email: "",
			phoneNumber: "",
      isPhoneValid: true,
      verifyCode: ""
		}
	},
	validations: {
    email: {
      required, email
    },
  },
	methods: {
    ...mapActions('account', {
      SendCode: 'SendCode',
      confirmVerifyCode: 'confirmVerifyCode',
    }),
    ...mapActions('end_user', {
      endUserconfirmVerifyCode: 'confirmVerifyCode',
    }),
		openModal() {
      this.isShowConfirm = false;
      this.isPhoneValid = false;
			this.$modal.show("modal2Fa");
		},
		closeModal() {
      this.email = "";
      this.phoneNumber = "";
      this.$v.$reset();
			this.$modal.hide("modal2Fa");
		},
    onUpdatePhoneNumber(payload) {
      this.isPhoneValid = payload.isValid;
    },
		handleSendCode(isResend) {
      let value = this.email.toLowerCase();
      if (this.twoFAType == 'email') {
        this.$v.email.$touch();
        if (this.$v.email.$anyError) return;
      } else {
        if (!this.isPhoneValid) return;
        value = this.phoneNumber.replace(/-/g, '');
      }

      const codeData = {
        user_id: this.userID,
        type: this.twoFAType,
        value: value,
        isResend: isResend,
      }

      this.SendCode({codeData, userType: this.userType}).then(async (res) => {
        if (res.message.title == 'Success') {
          this.isShowConfirm = true;
        }
      });
		},
    onComplete(v) {
      this.closeModal();
      let that = this;
      let codeData = {
        isRememberDevice: false,
        user_id: this.userID,
        code: v,
        userType: this.userType
      }
      if (this.isRememberDevice) {
        const deviceDetector = new DeviceDetector();
        const userAgent = window.navigator.userAgent;
        const device = deviceDetector.parse(userAgent); 
        codeData = {
          isRememberDevice: true,
          user_id: this.userID,
          code: v,
          browser: device.client.name,
          os_name: device.os.name,
          os_version: device.os.version,
          os_platform: device.os.platform,
          device_type: device.device.type
        }
      }
      if (this.userType == "Admin") {
        this.confirmVerifyCode(codeData).then(() => {
          localStorage.setItem("user_type_logged_in", "Reseller");
          that.$router.push(that.$route.query.redirect || "/");
        });
      } else if (this.userType == "EndUser") {
        this.endUserconfirmVerifyCode(codeData).then(() => {
          localStorage.setItem("user_type_logged_in", "EndUser");
          that.$router.push(that.$route.query.redirect || "/enduser/home");
        });
      } else if (this.userType == "UpdateEmail") {
        this.endUserconfirmVerifyCode(codeData).then(() => {
          that.$emit("confirmedCode");
        });
      }
    }
	},
}
</script>

<style>
.modal-2fa .vm--modal {
  height: unset !important;
  overflow: unset !important;
}

.v-otp-input {
  direction: ltr;
} 

input.twofa-email {
  font-size: 30px;
}

.primary-btn-color {
  background-color: #29abe2 !important;
}
</style>
