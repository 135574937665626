<template>
   <div class="p-7 payCard_block">
    <h6 class="font-weight-bold">
      {{ cardData.address1 }} - {{ cardData.name }}
    </h6>
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div>
        <div class="address_block blue-grey--text text--darken-1 font-weight-bold">
          {{ cardData.address2 }}
        </div>
        <div class="address_block blue-grey--text text--darken-1 font-weight-bold">
          {{ cardData.state }} {{ cardData.city }} {{ cardData.zip_code }} {{ cardData.country }}
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-btn
          class="text-none mx-5"
          small
          @click="deleteCard"
        >
          {{ $t("Delete") }}
        </v-btn>
        <v-btn
          class="text-none"
          small
          @click="editCard"
        >
          {{ $t("Edit") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressCard',
  props: {
    cardData: {
      type: Object,
      required: true,
      default: {}
    },
  },
  methods: {
    deleteCard() {
      this.$emit('deleteCard', this.cardData.id);
    },
    editCard() {
      this.$emit('editCard', this.cardData);
    },
  }
}
</script>

<style lang="scss" scoped>
  .address_block {
    max-width: 280px;
  }
</style>